import React from "react";
// import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Toggle from "./Toggle";
import TimeDropDown from "../components/meetings/TimeDropDown";
import { FaTrashAlt } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { timeData } from "../assets/dummyData/timeData";
import TimeInput from "../components/meetings/TimeInput";

const DaySelector = ({
  title,
  options,
  toggleStatus,
  onToggleChange,
  setFromTime,
  setToTime,
  fromTime,
  toTime,
  handleAdd,
  handleRemove,
}) => {
  return (
    <div className="w-full  select-none my-3 ">
      <div className=" min-h-[50px] item-center border-[1.5px] bg-[#F8F8F8] font-thin border-border rounded-md w-full px-1 md:px-5 caret-slate-600 transition-all duration-500 ease-in-out focus:border-secoundry">
        <div className="flex flex-row justify-between md:justify-between items-center">
          <div className={` ${toggleStatus ? "": "mt-3"} flex gap-1 items-center`}>
            <Toggle onChange={onToggleChange} active={toggleStatus} />
            <h2 className=" text-[#000000] font-normal lg:mx-5">{title}</h2>
          </div>

          <div className="bg-[#F8F8F8] rounded-md">
            {toggleStatus ? (
              <>
            {options?.map((item, index) => (
              <div key={index} className=" relative">
                <div className="flex my-1 items-center ">
                  <div className=" w-full">
                    <TimeDropDown
                      setSelectedText={(r) => setFromTime(r, index)}
                      selectedText={item.from}
                      height="h-[30px]"
                      dropDownClassName={"top-[3rem] h-auto -right-24"}
                      placeholder="9:30"
                      options={timeData}
                    />
                    <TimeInput 
                      setSelectedText={(r) => setFromTime(r, index)}
                      selectedText={item.from}
                    />
                  </div>
                  <div className="md:w-10 ">
                    <p className="mx-[2px] md:mx-4">to</p>
                  </div>
                  <div className="relative w-full">
                    <TimeDropDown
                      height="h-[30px]"
                      setSelectedText={(r) => setToTime(r, index)}
                      selectedText={item.to}
                      dropDownClassName={" top-[3rem] h-auto w-[500%] right-6"}
                      placeholder="5:30"
                      options={timeData}
                    />
                    <TimeInput 
                      setSelectedText={(r) => setToTime(r, index)}
                      selectedText={item.to}
                    />
                  </div>
                  <div className="  ">
                    {index > 0 ? (
                      <div className="flex  items-center">
                        <MdAddCircle
                          onClick={handleAdd}
                          className="text-[17px]  text-primary md:mx-2 cursor-pointer hover:text-green-400"
                        />
                        <FaTrashAlt
                          onClick={() => {
                            handleRemove(index);
                          }}
                          className="text-[14px] text-primary cursor-pointer hover:text-red-500 "
                        />
                      </div>
                    ) : (
                      <MdAddCircle
                        onClick={handleAdd}
                        className="  text-[17px]  text-primary mx-2 cursor-pointer hover:text-green-400"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
            </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DaySelector;
