import React, { useState } from "react";
import InputField from "../components/InputField";
import DescriptionInput from "../components/meetings/DescriptionInput";
// import axios from "axios";
import CustomModal from "../components/CustomModal";
import { Puff, ThreeCircles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import MeetingsTable from "../components/meetings/MeetingsTable";
import Search from "../components/meetings/Search";
import GoogleCalendarIcon from "../assets/images/Icons/GoogleCalendarIcon";
import { useFormik } from "formik";
import { meetingDataSchema } from "../utils/schemas";
// import MeetingInput from "../components/meetings/MeetingInput";
import MeetingsIcon from "../assets/images/Icons/MeetingsIcon";
import NewMeetingIcon from "../assets/images/Icons/NewMeetingIcon";
import DropDown from "../components/meetings/DropDown";
import { setTopic, setUserData } from "../redux/AppSlice";
import { CgClose } from "react-icons/cg";
import OutlookCalendarIcon from "../assets/images/Icons/OutlookCalendarIcon";
import InputFieldM from "../components/meetings/InputFieldM";
import DaySelector from "../components/meetings/DaySelector";
import TimezoneSelect from "react-timezone-select";
import { useNavigate } from "react-router-dom";
import api from "../services/axiosInstance";

const Meetings = () => {
  const pakaData = useSelector(
    (state) => state.content.pakaData
  ).meetingContent;
  const userData = useSelector((state) => state.app.userData);
  const MeetingsList = useSelector((state) => state.app.topic);
  const contactSearch = useSelector((state) => state.contact.contactSearch);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [additionalEmail, setAdditionalEmail] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Create New");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedMeetings, setSelectedMeetings] = useState([]);
  const [createMeetingData, setCreateMeetingData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    // setTouched,
    // setFieldError,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: meetingDataSchema,
    initialValues: {
      user_id:
        createMeetingData != null
          ? createMeetingData.user_id
          : userData?.id || "",
      topic: createMeetingData != null ? createMeetingData.topic : "",
      duration: createMeetingData != null ? createMeetingData.duration : "",
      breakTime: createMeetingData != null ? createMeetingData.breakTime : "",
      location:
        createMeetingData != null
          ? createMeetingData.location
          : { type: "", address: "" },
      calendar: createMeetingData != null ? createMeetingData.calendar : "",
      description:
        createMeetingData != null ? createMeetingData.description : "",
      availability:
        createMeetingData != null
          ? createMeetingData.availability
          : [
              {
                day: "Monday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Tuesday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Wednesday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Thursday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Friday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Saturday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Sunday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
            ],
      timeZone:
        createMeetingData != null
          ? createMeetingData.timeZone
          : {
              abbrev: "YEKT",
              altName: "Yekaterinburg Standard Time",
              label: "(GMT+5:00) Ekaterinburg",
              offset: 5,
              value: "Asia/Yekaterinburg",
            },
      meetingWindow:
        createMeetingData != null ? createMeetingData.meetingWindow : "",
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (selectedOption === "Create New") {
        createOperationTopic(values, resetForm);
      } else if (selectedOption === "Edit") {
        values._id = createMeetingData?._id || "";
        updateOperationTopic(values);
        setSelectedMeetings([]);
      }
    },
  });

  // Handle meeting availability
  const handleToggle = (day) => {
    const newArray = values.availability?.map((item, i) => {
      if (item.day === day) {
        return { ...item, active: item.active === true ? false : true };
      } else {
        return item;
      }
    });
    setFieldValue("availability", newArray);
    // setWorkingDaysData(newArray);
  };

  const handleAdd = (day) => {
    const newArray = values.availability?.map((item, i) => {
      if (item.day === day) {
        return {
          ...item,
          meetingTime: item.meetingTime.concat({ from: "", to: "" }),
        };
      } else {
        return item;
      }
    });

    // setWorkingDaysData(newArray);
    setFieldValue("availability", newArray);
  };

  const handleRemove = (day, itemIndex) => {
    const newArray = values.availability?.map((item, i) => {
      if (item.day === day) {
        return {
          ...item,
          meetingTime: item.meetingTime.slice(0, -1),
        };
      } else {
        return item;
      }
    });

    setFieldValue("availability", newArray);
  };

  const handleFromTime = (day, time, itemIndex) => {
    const newArray = values.availability?.map((item, i) => {
      if (item.day === day) {
        const w = item?.meetingTime?.map((it, index) => {
          if (itemIndex === index) {
            return {
              ...it,
              from: time,
            };
          } else {
            return it;
          }
        });
        return {
          ...item,
          meetingTime: w,
        };
      } else {
        return item;
      }
    });
    setFieldValue("availability", newArray);
  };

  const handleToTime = (day, time, itemIndex) => {
    const newArray = values.availability?.map((item, i) => {
      if (item.day === day) {
        const w = item?.meetingTime?.map((it, index) => {
          if (itemIndex === index) {
            return {
              ...it,
              to: time,
            };
          } else {
            return it;
          }
        });
        return {
          ...item,
          meetingTime: w,
        };
      } else {
        return item;
      }
    });
    setFieldValue("availability", newArray);
  };

  // Search the contact
  const keys = ["topic", "calendar", "description"];
  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) => {
        return item[key].match(
          new RegExp(contactSearch.replace(/[+*(){}?\\[\]]/g, "\\$&"), "i")
        );
      })
    );
  };

  // API call for create topic
  const createOperationTopic = async (topicData, resetForm) => {
    setLoader(true);
    // console.log(topicData, "meeting DAta");
    api
      .post(`/operation/create_operation`, topicData)
      .then(async ({ data }) => {
        resetForm();
        setShowModel(false);
        dispatch(setTopic(data.operationTopicList));
        // console.log(data);
        setLoader(false);
        toast.success("Meeting Topic Created Successfully", {
          theme: "colored",
          position: "top-center",
          autoClose: 1000,
          progress: false,
          hideProgressBar: true,
          style: { backgroundColor: "green" },
        });
      })
      .catch((error) => {
        if (
          error.response.data.success === false &&
          error.response.data.message === "Token Missing"
        ) {
          setLoader(false);
          setShowModel(false);
          toast.error("Please Login Before create meeting topic!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
          navigate("/");
        } else {
          setLoader(false);
          setShowModel(false);
          toast.error("Something went wrong!!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
        }
        console.log(error, "create Meeting error");
      });
  };

  const deleteOperationTopic = async (topicData) => {
    setDeleteLoader(true);
    // console.log(topicData);
    api
      .post(`/operation/delete_operations`, {
        user_id: userData?.id || "",
        _ids: topicData,
      })
      .then(async ({ data }) => {
        setDeleteLoader(false);
        dispatch(setTopic(data.operationTopicList));
        setSelectedMeetings([]);
        setShowModel(false);
        setSelectAll(false);
        toast.success("Meeting Deleted Successfully", {
          theme: "colored",
          position: "top-center",
          autoClose: 1000,
          progress: false,
          hideProgressBar: true,
          style: { backgroundColor: "green" },
        });
      })
      .catch((error) => {
        if (
          error.response.data.success === false &&
          error.response.data.message === "Token Missing"
        ) {
          setDeleteLoader(false);
          setShowModel(false);
          toast.error("Please Login Before delete meeting topic!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
          navigate("/");
        } else {
          setDeleteLoader(false);
          setShowModel(false);
          toast.error("Something went wrong!!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
        }
        console.log(error, "delete Meeting topic error");
      });
  };

  const updateOperationTopic = async (topicData) => {
    setLoader(true);
    // console.log(topicData);
    api
      .post(`/operation/update_operation`, topicData)
      .then(async ({ data }) => {
        setLoader(false);
        dispatch(setTopic(data.operationTopicList));
        setSelectedMeetings([]);
        setShowModel(false);
        toast.success("Meeting Updated Successfully", {
          theme: "colored",
          position: "top-center",
          autoClose: 3000,
          progress: false,
          hideProgressBar: true,
          style: { backgroundColor: "green" },
        });
      })
      .catch((error) => {
        if (
          error.response.data.success === false &&
          error.response.data.message === "Token Missing"
        ) {
          setLoader(false);
          setShowModel(false);
          toast.error("Please Login Before update meeting topic!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
          navigate("/");
        } else {
          setLoader(false);
          setShowModel(false);
          toast.error("Something went wrong!!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
        }
        console.log(error, "update error");
      });
  };

  const addAdditionalEmail = async (email) => {
    setLoader(true);
    api
      .post(`/auth/email_list_update`, { user_id: userData?.id, email: email })
      .then(async ({ data }) => {
        setLoader(false);
        // dispatch(setTopic(data.operationTopicList));
        toast.success("Email Updated Successfully, Now Link Google Calendar", {
          theme: "colored",
          position: "top-center",
          autoClose: 5000,
          progress: false,
          hideProgressBar: true,
          style: { backgroundColor: "green" },
        });
      })
      .catch((error) => {
        if (
          error.response.data.success === false &&
          error.response.data.message === "Token Missing"
        ) {
          setLoader(false);
          setShowModel(false);
          toast.error("Please Login Before add additional email!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
          navigate("/");
        } else {
          setLoader(false);
          setShowModel(false);
          toast.error("Something went wrong!!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
        }
        console.log(error, "update error");
      });
  };

  // const connectGoogleCalendar = async () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/calendar/google/${userData?.id}`)
  //     .then(async (result) => {
  //       toast.success("Google Calendar Connected Successfully", {
  //         theme: "colored",
  //         position: "top-center",
  //         autoClose: 5000,
  //         progress: false,
  //         hideProgressBar: true,
  //         style: { backgroundColor: "green" },
  //       });
  //     })
  //     .catch((error) => {
  //       toast.error("Something went wrong!!", {
  //         theme: "colored",
  //         position: "top-center",
  //         autoClose: 1000,
  //         progress: false,
  //         hideProgressBar: true,
  //       });
  //       console.log(error, "linking error");
  //     });
  // };

  const disconnectGoogleCalendar = async () => {
    setLoader(true);
    api
      .post(`/calendar/disconnect_google_calendar`, { user_id: userData?.id })
      .then(async ({ data }) => {
        // setDeleteLoader(false);
        api
          .post(`/auth/get_user_data`, {
            user_id: userData?.id,
          })
          .then((result) => {
            dispatch(setUserData(result?.data?.user || {}));
            setLoader(false);
            toast.success("Google Calendar disconnected Successfully", {
              theme: "colored",
              position: "top-center",
              autoClose: 5000,
              progress: false,
              hideProgressBar: true,
              style: { backgroundColor: "green" },
            });
          });
      })
      .catch((error) => {
        if (
          error.response.data.success === false &&
          error.response.data.message === "Token Missing"
        ) {
          setLoader(false);
          setShowModel(false);
          toast.error("Please Login Before add additional email!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
          navigate("/");
        } else {
          setLoader(false);
          setShowModel(false);
          toast.error("Something went wrong!!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
        }
        console.log(error, "update error");
      });
  };
  const handleInputChange = (event) => {
    setAdditionalEmail(event.target.value);
  };

  return (
    <div className=" px-3 relative">
    <CustomModal isOpen={loader}>
        <div className=" flex items-center justify-center">
          <div className=" bg-white p-8 h-fit w-fit rounded-md">
            <Puff
              height="100"
              width="100"
              radius={1}
              color="#FF0066"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
      <CustomModal
        isOpen={showModel}
        className={` lg:w-8/12 lg:ml-[20%] -mt-12 lg:mt-0`}
      >
        {selectedOption === "Delete" && (
          <div className=" flex flex-col rounded-md items-center bg-white w-full mx-3 lg:mx-0 md:w-1/2 h-fit mt-36 lg:mt-12 py-6">
            <img
              className=" w-12 h-12"
              alt="del"
              src={require("../assets/images/trash.png")}
            />
            {deleteLoader ? (
              <div className="  my-5  w-full flex flex-col items-center">
                <ThreeCircles
                  height="60"
                  width="60"
                  color="#000032"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                />
                <p className=" mt-5 text-primary">Deleting the Meetings.....</p>
              </div>
            ) : (
              <div className=" w-full flex flex-col items-center">
                <p className=" text-appgray text-[18px] mx-3 lg:mx-0 lg:w-8/12 flex text-center">
                  Deleting a Meeting will delete it permanently, are you sure?
                </p>
                <button
                  onClick={() => deleteOperationTopic(selectedMeetings)}
                  className={` flex justify-center w-1/2 lg:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
                >
                  Yes, Delete
                </button>
                <button
                  onClick={() => {
                    setShowModel(false);
                    setSelectedMeetings([]);
                  }}
                  className=" mt-3"
                >
                  No, Keep it
                </button>
              </div>
            )}
          </div>
        )}
        {selectedOption === "Create New" && (
          <div className="rounded-md bg-white w-[94%] lg:max-w-[50%] lg:min-w-[620px] mx-3 lg:mx-0 md:w-8/12 mt-16 lg:mt-8 py-6 h-fit">
            <div className=" w-full flex items-center justify-between mx-4">
              <p className="text-lg text-primary font-semibold mb-4">
                Create A Meeting
              </p>
              <CgClose
                onClick={() => {
                  setShowModel(false);
                }}
                className="text-2xl cursor-pointer mr-8 mb-2"
              />
            </div>
            <hr />

            <div className=" mx-[2%] mt-5 flex flex-col items-center md:items-start gap-3">
              <div className="w-[95%] lg:w-full">
                <InputFieldM
                  onChange={handleChange("topic")}
                  onBlur={handleBlur("topic")}
                  title="Topic*"
                  value={values.topic}
                  placeholder="eg. Demo Paka AI"
                  info={pakaData.topic}
                  touched={touched.topic}
                  errors={errors.topic}
                />
                <div className=" h-3 pt-1  ">
                  {touched.topic && errors.topic && (
                    <p className=" text-[11px] text-red-500">{errors.topic}</p>
                  )}
                </div>
              </div>
              <div className="w-[95%] lg:w-full mt-2">
                <DropDown
                  title={"Duration*"}
                  selectedText={values.duration}
                  placeholder={"eg. 60 minutes"}
                  options={["10", "15", "20", "30", "45", "60", "90", "120"]}
                  setSelectedText={(r) => {
                    setFieldValue("duration", r);
                  }}
                  dropDownClassName="overflow-y-scroll h-56 z-50"
                  className={"w-[28rem]"}
                  info={pakaData.duration}
                  unitType={"minutes"}
                  touched={touched.duration}
                  errors={errors.duration}
                />
                <div className=" h-3 pt-1  ">
                  {touched.duration && errors.duration && (
                    <p className=" text-[11px] text-red-500">
                      {errors.duration}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-[95%] lg:w-full mt-2">
                <DropDown
                  title={"Break Time*"}
                  selectedText={values.breakTime}
                  placeholder={"eg. 60 minutes"}
                  options={[
                    "5",
                    "10",
                    "15",
                    "20",
                    "25",
                    "30",
                    "45",
                    "60",
                    "90",
                    "120",
                  ]}
                  setSelectedText={(r) => {
                    setFieldValue("breakTime", r);
                  }}
                  dropDownClassName="overflow-y-scroll h-56 z-50"
                  className={"w-[28rem]"}
                  info={pakaData.breakTime}
                  unitType={"minutes"}
                  touched={touched.breakTime}
                  errors={errors.breakTime}
                />
                <div className=" h-3 pt-1  ">
                  {touched.breakTime && errors.breakTime && (
                    <p className=" text-[11px] text-red-500">
                      {errors.breakTime}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-[95%] lg:w-full mt-2">
                <DropDown
                  title={"Location*"}
                  selectedText={values.location.type}
                  placeholder={"eg. Frontal"}
                  options={["Frontal", "Google Meet", "Phone call"]}
                  setSelectedText={(r) => {
                    setFieldValue("location.type", r);
                  }}
                  dropDownClassName="overflow-y-scroll min-h-24 max-h-56 z-50"
                  className={"w-[28rem]"}
                  info={pakaData.location}
                  unitType={""}
                  touched={touched.location?.type}
                  errors={errors.location?.type}
                />
                <div className=" h-3 pt-1  ">
                  {touched.location?.type && errors.location?.type && (
                    <p className=" text-[11px] text-red-500">
                      {errors.location?.type}
                    </p>
                  )}
                </div>
                <div>
                  {values.location.type === "Frontal" && (
                    <>
                      <InputFieldM
                        onChange={handleChange("location.address")}
                        onBlur={handleBlur("location.address")}
                        title="Address"
                        value={values.location.address}
                        placeholder="eg. Demo Paka AI"
                        info="Enter the address for Frontal."
                      />
                      <div className=" h-3 pt-1  ">
                        {touched.location?.address &&
                          errors.location?.address && (
                            <p className=" text-[11px] text-red-500">
                              {errors.location?.address}
                            </p>
                          )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="w-[95%] lg:w-full mt-10">
              <DescriptionInput
                title="Description*"
                onChange={handleChange("description")}
                onBlur={handleBlur("description")}
                value={values.description}
                placeholder="eg - Paka AI Demo Session, an enlightening showcase where you'll experience the transformation capabilities of our AI-powered conversational bots and custom interactive voice response (IVR) systems. This session is your opportunity to see how Paka AI can streamline your customer service, ensuring 24/7 availability and significantly enhancing user satisfaction."
                info={pakaData.description}
                className={`ml-4`}
              />
              <div className=" h-3 pt-1 ml-3 ">
                {touched.description && errors.description && (
                  <p className=" text-[11px] text-red-500">
                    {errors.description}
                  </p>
                )}
              </div>
            </div>
            <div className="w-[90%] lg:w-[95%] mx-4 mt-3">
              <p>Availability*</p>
              <div className=" h-3 pt-1 ml-3 ">
                {touched.availability && errors.availability && (
                  <p className=" text-[11px] text-red-500">
                    From time must be less than To time
                  </p>
                )}
              </div>
              {values.availability?.map((item, index) => (
                <div key={index}>
                  <DaySelector
                    title={item.day}
                    options={item.meetingTime}
                    toggleStatus={item.active}
                    setFromTime={(r, i) => {
                      handleFromTime(item.day, r, i);
                    }}
                    setToTime={(t, i) => {
                      handleToTime(item.day, t, i);
                    }}
                    handleAdd={() => handleAdd(item.day)}
                    onToggleChange={() => handleToggle(item.day)}
                    handleRemove={(r) => {
                      handleRemove(item.day, r);
                    }}
                  />
                </div>
              ))}
            </div>

            <div className=" mx-[2%] mt-3 flex flex-col items-center md:items-start gap-3">
              <div className="w-[95%] lg:w-full">
                <h3 className=" text-primary">Timezone*</h3>
                <div className=" w-full">
                  <TimezoneSelect
                    value={values.timeZone}
                    onChange={(t) => {
                      setFieldValue("timeZone", t);
                    }}
                  />
                </div>
                <div className=" h-3 pt-1  ">
                  {touched.timeZone && errors.timeZone && (
                    <p className=" text-[11px] text-red-500">
                      {errors.timeZone}
                    </p>
                  )}
                </div>
              </div>
              <div className="w-[95%] lg:w-full mt-2">
                <DropDown
                  title={"Meeting window*"}
                  selectedText={values.meetingWindow}
                  placeholder={
                    "How many days in advance can appointments be made?"
                  }
                  options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                  setSelectedText={(r) => {
                    setFieldValue("meetingWindow", r);
                  }}
                  dropDownClassName="overflow-y-scroll h-56 z-50"
                  className={"w-[28rem]"}
                  info={pakaData.meetingWindow}
                  unitType={""}
                />
                <div className=" h-3 pt-1  ">
                  {touched.meetingWindow && errors.meetingWindow && (
                    <p className=" text-[11px] text-red-500">
                      {errors.meetingWindow}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full flex items-center mt-6 text-center">
              <button
                type="submit"
                onClick={handleSubmit}
                className={`flex justify-center w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
              >
                Save
              </button>
              <button
                onClick={() => {
                  setShowModel(false);
                }}
                className="w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry py-2 px-3 rounded-md mx-3 text-primary border border-black"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {selectedOption === "Edit" && (
          <div className="rounded-md bg-white w-[94%] lg:max-w-[50%] lg:min-w-[620px] mx-3 lg:mx-0 md:w-8/12 mt-16 lg:mt-8 py-6 h-fit">
            <div className="w-full flex items-center justify-between mx-4">
              <p className="text-lg text-primary font-bold mb-4">
                Update Meeting
              </p>
              <CgClose
                onClick={() => {
                  setShowModel(false);
                  setSelectedMeetings([]);
                }}
                className="text-2xl cursor-pointer mr-8 mb-2"
              />
            </div>
            <hr />

            <div className="mx-[2%] mt-3 flex flex-col items-center md:items-start gap-3">
              <div className="w-full">
                <InputFieldM
                  onChange={handleChange("topic")}
                  onBlur={handleBlur("topic")}
                  title="Topic*"
                  value={values.topic}
                  placeholder="eg. Demo Paka AI"
                  className={`flex-auto w-[28rem]`}
                  info={pakaData.topic}
                />
              </div>
              <div className="w-full mt-2">
                <DropDown
                  title={"Duration*"}
                  selectedText={values.duration}
                  placeholder={"eg. 60 minutes"}
                  options={["10", "15", "20", "30", "45", "60", "90", "120"]}
                  setSelectedText={(r) => {
                    setFieldValue("duration", r);
                  }}
                  dropDownClassName="overflow-y-scroll h-56 z-50"
                  className={"w-[28rem]"}
                  info={pakaData.duration}
                  unitType={"minutes"}
                />
              </div>
              <div className="w-full mt-2">
                <DropDown
                  title={"Break Time*"}
                  selectedText={values.breakTime}
                  placeholder={"eg. 60 minutes"}
                  options={[
                    "5",
                    "10",
                    "15",
                    "20",
                    "25",
                    "30",
                    "45",
                    "60",
                    "90",
                    "120",
                  ]}
                  setSelectedText={(r) => {
                    setFieldValue("breakTime", r);
                  }}
                  dropDownClassName="overflow-y-scroll h-56 z-50"
                  className={"w-[28rem]"}
                  info={pakaData.breakTime}
                  unitType={"minutes"}
                />
              </div>
              <div className="w-full mt-2">
                <DropDown
                  title={"Location*"}
                  selectedText={values.location.type}
                  placeholder={"eg. Frontal"}
                  options={["Frontal", "Google Meet", "Phone call"]}
                  setSelectedText={(r) => {
                    setFieldValue("location.type", r);
                  }}
                  dropDownClassName="overflow-y-scroll min-h-24 max-h-56 z-50"
                  className={"w-[28rem]"}
                  info={pakaData.location}
                  unitType={""}
                  touched={touched.location?.type}
                  errors={errors.location?.type}
                />
                <div className=" h-3 pt-1  ">
                  {touched.location?.type && errors.location?.type && (
                    <p className=" text-[11px] text-red-500">
                      {errors.location?.type}
                    </p>
                  )}
                </div>
                <div>
                  {values.location.type === "Frontal" && (
                    <>
                      <InputFieldM
                        onChange={handleChange("location.address")}
                        onBlur={handleBlur("location.address")}
                        title="Address"
                        value={values.location.address}
                        placeholder="eg. Demo Paka AI"
                        info="Enter the address for Frontal."
                      />
                      <div className=" h-3 pt-1  ">
                        {touched.location?.address &&
                          errors.location?.address && (
                            <p className=" text-[11px] text-red-500">
                              {errors.location?.address}
                            </p>
                          )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-10">
              <DescriptionInput
                title="Description*"
                onChange={handleChange("description")}
                onBlur={handleBlur("description")}
                value={values.description}
                placeholder="eg - Paka AI Demo Session, an enlightening showcase where you'll experience the transformation capabilities of our AI-powered conversational bots and custom interactive voice response (IVR) systems. This session is your opportunity to see how Paka AI can streamline your customer service, ensuring 24/7 availability and significantly enhancing user satisfaction."
                info={pakaData.description}
                className={`ml-4`}
              />
            </div>
            <div className="w-[95%] mx-4 mt-3">
              <p>Availability*</p>
              <div className=" h-3 pt-1 ml-3 ">
                {touched.availability && errors.availability && (
                  <p className=" text-[11px] text-red-500">
                    From time must be less than To time
                  </p>
                )}
              </div>
              {values.availability?.map((item, index) => (
                <div key={index}>
                  <DaySelector
                    title={item.day}
                    options={item.meetingTime}
                    toggleStatus={item.active}
                    setFromTime={(r, i) => {
                      handleFromTime(item.day, r, i);
                    }}
                    setToTime={(t, i) => {
                      handleToTime(item.day, t, i);
                    }}
                    handleAdd={() => handleAdd(item.day)}
                    onToggleChange={() => handleToggle(item.day)}
                    handleRemove={(r) => {
                      handleRemove(item.day, r);
                    }}
                  />
                </div>
              ))}
            </div>

            <div className=" mx-[2%] mt-3 flex flex-col items-center md:items-start gap-3">
              <div className="w-full">
                <h3 className=" text-primary">Timezone*</h3>
                <div className=" w-full">
                  <TimezoneSelect
                    value={values?.timeZone || {}}
                    onChange={(t) => {
                      setFieldValue("timeZone", t);
                    }}
                  />
                </div>
              </div>
              <div className="w-full mt-2">
                <DropDown
                  title={"Meeting window*"}
                  selectedText={values.meetingWindow}
                  placeholder={
                    "How many days in advance can appointments be made?"
                  }
                  options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                  setSelectedText={(r) => {
                    setFieldValue("meetingWindow", r);
                  }}
                  dropDownClassName="overflow-y-scroll h-56 z-50"
                  className={"w-[28rem]"}
                  info={pakaData.meetingWindow}
                  unitType={""}
                />
              </div>
            </div>

            <div className="w-full flex items-center mt-6 text-center">
              <button
                type="submit"
                onClick={handleSubmit}
                className={`flex justify-center w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
              >
                Save
              </button>
              <button
                onClick={() => {
                  setShowModel(false);
                  setSelectedMeetings([]);
                }}
                className="w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry py-2 px-3 rounded-md mx-3 text-primary border border-black"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {selectedOption === "Link Calendar" && (
          <div className="rounded-lg bg-white w-full mx-3 lg:mx-0 md:w-8/12 h-fit mt-[60%] lg:mt-[25%] lg:w-[55%] py-6">
            <div className="flex justify-between mx-5">
              <p className="text-2xl font-semibold">Calendar</p>
              <CgClose
                onClick={() => {
                  setShowModel(false);
                }}
                className="text-xl cursor-pointer"
              />
            </div>
            <hr className="my-3" />
            <div className="flex justify-between mx-2">
              <div className="flex gap-4">
                <GoogleCalendarIcon className="w-[23px] h-[23px]" />
                <p className="text-xl font-medium">Google Calendar</p>
              </div>

              {userData.refreshToken ? (
                <button
                  onClick={() => {
                    // addAdditionalEmail(additionalEmail);
                    setSelectedOption("Disconnect Google Calendar");
                  }}
                  className={`text-sm font-bold w-28 flex justify-center transition-all duration-300 ease-in-out hover:border-secoundry p-2 rounded-md text-white bg-secoundry`}
                >
                  Disconnect
                </button>
              ) : (
                <button
                  onClick={() => {
                    // addAdditionalEmail(additionalEmail);
                    setSelectedOption("Disconnect Google Calendar");
                  }}
                  className={`text-sm font-bold w-28 flex justify-center transition-all duration-300 ease-in-out hover:border-secoundry p-2 rounded-md text-white bg-primary`}
                >
                  Connect
                </button>
              )}
            </div>
            <hr className="my-3" />
            <div className="flex justify-between mx-2">
              <div className="flex gap-4">
                <OutlookCalendarIcon className="w-[23px] h-[23px]" />
                <p className="text-xl font-medium">Microsoft Office Outlook</p>
              </div>
              <button
                onClick={() => {
                  // addAdditionalEmail(additionalEmail);
                }}
                className={`text-sm font-bold w-28 flex justify-center transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary p-2 rounded-md text-white`}
              >
                upcoming
              </button>
            </div>
          </div>
        )}
        {selectedOption === "Disconnect Google Calendar" && (
          <>
            <div className="rounded-lg bg-white w-full mx-3 lg:mx-0 md:w-8/12 h-fit mt-[60%] md:mt-[34%] lg:mt-[22%] lg:w-[55%] py-6">
              {!userData.refreshToken ? (
                <>
                  <div className=" w-full flex flex-col items-center">
                    <GoogleCalendarIcon />
                    <p className="text-lg text-primary font-bold mb-2">
                      Link Google Calendar
                    </p>
                    <p className="text-sm text-primary mx-5 mb-4 text-center">
                      Please note if you want to link other gmail account other
                      then user Email then first save additional email then
                      click on link google calendar
                    </p>
                  </div>

                  <div className="mx-[5%] lg:flex justify-start gap-5">
                    <InputField
                      onChange={handleInputChange}
                      title="Email"
                      value={additionalEmail}
                      placeholder="eg. info@paka.ai"
                      className={`flex-auto w-[20rem]`}
                    />
                    <div className="w-full mt-2">
                      <button
                        onClick={() => {
                          addAdditionalEmail(additionalEmail);
                        }}
                        className={`flex justify-center transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary p-2 rounded-md text-white`}
                      >
                        Save Email
                      </button>
                    </div>
                  </div>

                  <div className=" w-full flex flex-col items-center">
                    {/* <a
                      href={`${process.env.REACT_APP_BASE_URL}/calendar/google/${userData?.id}`}
                      className={`flex gap-2 justify-center w-8/12 lg:w-[15rem] mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-3 rounded-md mx-3 text-white`}
                    >
                      <GoogleCalendarIcon className="w-7 h-7" />
                      <p>Link Google Calendar</p>
                    </a> */}
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}/calendar/google`}
                      className={`flex gap-2 justify-center w-8/12 lg:w-[15rem] mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-3 rounded-md mx-3 text-white`}
                    >
                      <GoogleCalendarIcon className="w-7 h-7" />
                      <p>Link Google Calendar</p>
                    </a>
                    <button
                      onClick={() => {
                        setShowModel(false);
                      }}
                      className=" mt-3"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className=" w-full flex flex-col">
                    <div className="flex justify-between mx-4">
                      <div className="flex gap-3">
                        <GoogleCalendarIcon className="w-[28px] h-[28px]" />
                        <p className="text-lg text-primary font-bold mb-2">
                          Google Calendar
                        </p>
                      </div>
                      <CgClose
                        onClick={() => {
                          setShowModel(false);
                        }}
                        className="text-xl cursor-pointer"
                      />
                    </div>
                    <hr />
                    <p className=" mx-5 text-lg text-primary font-semibold mb-2">
                      Are you sure you want to disconnect ?
                    </p>
                    <p className="text-sm text-primary mx-5 mb-4">
                      Please note if you Disconnect the Google Calander your
                      bots will not able to create any meetings
                    </p>
                  </div>

                  <div className="w-full flex items-center text-center">
                    <button
                      onClick={() => {
                        disconnectGoogleCalendar();
                        setShowModel(false);
                      }}
                      className={` w-8/12 lg:w-1/2 xl:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-3 rounded-md mx-3 text-white`}
                    >
                      <p>Yes</p>
                    </button>
                    <button
                      onClick={() => {
                        setShowModel(false);
                      }}
                      className="w-8/12 lg:w-1/2 xl:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry py-2 px-3 rounded-md mx-3 text-primary border border-black"
                    >
                      No
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </CustomModal>
      <h1 className=" mt-6 lg:mt-0 text-2xl font-bold text-primary ">
        Meetings
      </h1>
      <div className="hidden md:flex justify-between items-center">
        <p className=" text-[11px] text-appgray">
          {/* {pakaData.meetings.subHeading} */}
          The bot will be able to schedule meetings directly in your calendar.
        </p>
        <div className="flex ">
          <button
            onClick={() => {
              setShowModel(true);
              setCreateMeetingData();
              setSelectedOption("Create New");
            }}
            className=" flex items-center bg-primary cursor-pointer  px-3 py-2 rounded-md mx-3"
          >
            <NewMeetingIcon color={"#fff"} />
            <p className=" text-white ml-2 text-[14px] font-semibold">
              New Meeting
            </p>
          </button>
          {/* <a href={`${process.env.REACT_APP_BASE_URL}/calendar/google`}> */}
          <div>
            <button
              onClick={() => {
                setAdditionalEmail("");
                setShowModel(true);
                setSelectedOption("Link Calendar");
                // handleGoogleAuth();
              }}
              className={`flex items-center cursor-pointer px-3 py-2 rounded-md mx-3 ${
                userData.refreshToken ? "bg-secoundry" : "bg-primary"
              }`}
            >
              <MeetingsIcon color={"#fff"} />
              {/* <GoogleCalendarIcon className="w-[10%] h-[10%]" /> */}
              <p className={`text-white ml-2 text-[14px] font-semibold`}>
                {userData.refreshToken
                  ? "Google Calendar Connected"
                  : "Connect  Calendar"}
              </p>
            </button>
          </div>
          {/* </a> */}
        </div>
      </div>
      <div className="md:hidden block items-center">
        <p className=" text-[11px] text-appgray">
          {/* {pakaData.meetings.subHeading} */}
          Take a look at your Meetings.
        </p>
        <div className="w-full mt-2">
          <button
            onClick={() => {
              setShowModel(true);
              setCreateMeetingData();
              setSelectedOption("Create New");
            }}
            className="mb-2 w-full flex justify-center items-center text-center bg-primary cursor-pointer px-2 py-2 rounded-md mx-2"
          >
            <NewMeetingIcon color={"#fff"} />
            <p className=" text-white mx-1 text-[14px] font-medium">
              New Meeting
            </p>
          </button>
          {/* <a href={`${process.env.REACT_APP_BASE_URL}/calendar/google`}> */}
          <button
            onClick={() => {
              // setShowModel(true);
              // setCreateMeetingData();
              // setSelectedOption("Link Google");
              setAdditionalEmail("");
              setShowModel(true);
              setSelectedOption("Link Calendar");
            }}
            className={`w-full flex gap-2 justify-center items-center bg-primary cursor-pointer -mr-1 pl-2 py-2 rounded-md ml-2 ${
              userData.refreshToken ? "bg-secoundry" : "bg-primary"
            }`}
          >
            {/* <GoogleCalendarIcon className="w-[10%] h-[10%]" /> */}
            <MeetingsIcon color={"#fff"} />
            <p className={`text-white text-[14px] font-medium `}>
              {userData.refreshToken
                ? "Google Calendar Connected"
                : "Connect  Calendar"}
            </p>
          </button>
          {/* </a> */}
        </div>
        <div className="md:hidden block mt-3 text-primary">
          <Search />
        </div>
      </div>
      <div className="hidden md:block static mt-4 rounded-2xl overflow-hidden shadow-tableShadow bg-white mb-12 lg:mb-0 ">
        <div className=" bg-primary h-10 lg:h-14 flex items-center px-3 gap-x-4 py-2  ">
          {selectedMeetings.length > 0 ? (
            <div className=" flex text-white gap-5 font-medium">
              <button
                onClick={() => {
                  setShowModel(true);
                  setSelectedOption("Delete");
                }}
                className="mt-1 h-10 text-center bg-primary border-2 border-white px-8 hover:bg-secoundry transition-all ease-in-out duration-300 rounded-md mx-3"
              >
                delete
              </button>
              <p className="w-[10rem] mt-3 font-medium">
                - {selectedMeetings.length} {"were chosen"}
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="w-full">
            <Search />
          </div>
        </div>

        <MeetingsTable
          listOfMeetings={search(MeetingsList)}
          setSelectedOption={(opt) => {
            setSelectedOption(opt);
          }}
          setShowModel={setShowModel}
          selectedMeetings={selectedMeetings}
          setSelectedMeetings={setSelectedMeetings}
          setCreateMeetingData={setCreateMeetingData}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
        />
      </div>
      <div className="md:hidden block static mt-4 rounded-2xl overflow-hidden shadow-tableShadow bg-white mb-12 lg:mb-0 ">
        <div className=" bg-primary h-10 flex items-center px-3 gap-x-4 py-2  ">
          {selectedMeetings.length > 0 ? (
            <div className=" flex text-white gap-5 font-medium">
              <button
                onClick={() => {
                  setShowModel(true);
                  setSelectedOption("Delete");
                }}
                className=" text-center bg-primary border-2 border-primary py-1.5 px-8 hover:bg-secoundry transition-all ease-in-out duration-300 rounded-md mx-3"
              >
                delete
              </button>
              <p className="mt-2 font-medium">
                - {selectedMeetings.length} {"were chosen"}
              </p>
            </div>
          ) : (
            ""
          )}
          {/* <Search /> */}
        </div>

        <MeetingsTable
          listOfMeetings={search(MeetingsList)}
          setSelectedOption={(opt) => {
            setSelectedOption(opt);
          }}
          setShowModel={setShowModel}
          selectedMeetings={selectedMeetings}
          setSelectedMeetings={setSelectedMeetings}
          setCreateMeetingData={setCreateMeetingData}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
        />
      </div>
    </div>
  );
};

export default Meetings;
