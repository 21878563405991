import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import HomeRoutes from "../routes/HomeRoutes";
import SearchBar from "../components/SearchBar";
import { HiBell } from "react-icons/hi";
import { MdKeyboardArrowDown } from "react-icons/md";
import CreateBotIcon from "../assets/images/Icons/CreateBotIcon";
import DashboardIcon from "../assets/images/Icons/DashboardIcon";
import BotsIcon from "../assets/images/Icons/BotsIcon";
import CallHistoryIcon from "../assets/images/Icons/CallHistoryIcon";
import MessageCenterIcon from "../assets/images/Icons/MessageCenterIcon";
import ContactsIcon from "../assets/images/Icons/ContactsIcon";
import MeetingsIcon from "../assets/images/Icons/MeetingsIcon";
import SupportBotIcon from "../assets/images/Icons/SupportBotIcon";
import SettingsIcon from "../assets/images/Icons/SettingsIcon";
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallHistoryData,
  setMessageCenterData,
  setUserData,
  storeBotsList,
  setLoading,
  setTopic,
  setSupportBot,
  setMeetings,
} from "../redux/AppSlice";
import {
  setBotFilteredCallHistory,
  setBotFilteredMeetings,
  setBotFilteredVoiceMails,
  setDateFilteredCallHistory,
  setDateFilteredMeetings,
  setDateFilteredVoiceMails,
} from "../redux/DashboardSlice";
import { setCallFilteredBot } from "../redux/CallSlice";
import { setContactsList } from "../redux/ContactSlice";
// import axios from "axios";
import ProfileDetails from "../components/ProfileDetails";
import {
  filterData,
  filterMeetingsData,
  filterVoiceMailsData,
} from "../utils/helper/dashboardFilterData";
import { Puff } from "react-loader-spinner";
import CustomModal from "../components/CustomModal";
import { planType } from "../utils/helper/plan&billingFunctions";
import api from "../services/axiosInstance";

const Layouts = () => {
  const userData = useSelector((state) => state.app.userData);
  const botsList = useSelector((state) => state.app.botsList);
  const loading = useSelector((state) => state.app.loading);
  const [menu, setMenu] = useState(false);
  const [mobileProfile, setMobileProfile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const usesDetail = planType(
    userData?.subscriptionPlanId,
    userData?.subscriptionStatus,
    userData?.subscriptionEndDate
  );

  const [MenuBar] = useState([
    {
      title: "Create Bot",
      path: "create-bot",
    },
    {
      title: "Dashboard",
      path: ["dashboard"],
    },
    {
      title: "Bots",
      path: ["bots", "bots/create-bot", "bots/edit-bot", "bots/duplicate-bot"],
    },
    {
      title: "Call History",
      path: ["call-history"],
    },
    {
      title: "Message Center",
      path: ["message-center"],
    },
    {
      title: "Contacts",
      path: ["contacts"],
    },
    {
      title: "Meetings",
      path: ["meetings"],
    },
    {
      title: "Support Bot",
      path: ["support-bot"],
    },
    {
      title: "Settings",
      path: ["settings"],
    },
  ]);
  // const [botList, setBotList] = useState(botsList);
  const [showProfile, setShowProfile] = useState(false);

  /*
  const getSupportBot = async (user_id) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get_support_bots`,
        {
          user_id: user_id,
        },
        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        dispatch(setSupportBot(data?.supportBotList || []));
      })
      .catch((error) => {
        console.log(error, "Error in getting Operation List");
      });
  };

  const getOperationTopic = async (user_id) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/operation/get_operation`,
        {
          user_id: user_id,
        },
        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        dispatch(setTopic(data?.operationTopicList || []));
      })
      .catch((error) => {
        console.log(error, "Error in getting Operation List");
      });
  };

  const getContactsData = async (user_id) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/contact/get_contacts`,
        {
          user_id: user_id,
        },
        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        dispatch(setContactsList(data?.contactList || []));
      })
      .catch((error) => {
        console.log(error, "Error in getting Contacts List");
      });
  };

  const getCallHistoryData = async (user_id) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/call/get_call_history`,
        {
          user_id: user_id,
        },
        // auth
        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        dispatch(setCallHistoryData(data?.call_history || []));
        dispatch(
          setBotFilteredCallHistory(filterData(data?.call_history || [], 2))
        );
        dispatch(
          setDateFilteredCallHistory(filterData(data?.call_history || [], 2))
        );
      })
      .catch((error) => {
        console.log(error, "callHistory data Error");
      });
  };

  const getMessageCenterData = async (user_id) => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/voicemail/get_voicemails`,
        {
          user_id: user_id,
        },
        // auth
        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        // console.log(data.voicemails, "messageCenter Data");
        dispatch(setMessageCenterData(data?.data || []));
      })
      .catch((error) => {
        console.log(error, "Error in getting Message center data");
      });
    // console.log(messageData, "Yogi");
  };

  const getBotsList = async (id) => {
    dispatch(setLoading(true));
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/bots/bot_data`,
        {
          user_id: id,
        },
        // auth
        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        // console.log(data, "list of bots");
        // setBotList(data);
        if (data?.length === 0) {
          navigate("/create-bot");
        }
        // dispatch(setTopic(getTopic(data)));
        dispatch(setLoading(false));
        dispatch(storeBotsList(data));
        // if (data.length === 0) {
        //   navigate("/create-bot");
        //   dispatch(setLoading(false));
        // }
        // if (data.length > 0) {
        //   navigate("/dashboard");
        //   getCallHistoryData(data);
        //   getMessageCenterData(data);
        // }
      });
    dispatch(setLoading(false));
  };
  const getUserDetails = async () => {
    dispatch(setLoading(true));
    // let useremail = localStorage.getItem("userEmail");
    let devOtp = sessionStorage.getItem("devOtp");
    let user_id = sessionStorage.getItem("user_id");
    if (devOtp && user_id) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/auth/get_user_data`,
          {
            user_id: user_id,
          },
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          dispatch(setUserData(result?.data?.user || {}));
          if (!result.data.user.subscriptionStatus) {
            navigate("/subscription");
          }
          getBotsList(result.data.user.id);
          getCallHistoryData(result.data.user.id);
          getMessageCenterData(result.data.user.id);
          getContactsData(result.data.user.id);
          getOperationTopic(result.data.user.id);
          getSupportBot(result.data.user.id);
        })
        .catch((error) => {
          if (
            error.response.data.success === false &&
            error.response.data.message === "Token Missing"
          ) {
            navigate("/");
          }
          console.log(error, "Error in getting User data");
        });
    } else {
      navigate("/check");
    }
  };
  */

  const getMeetings = async (user_id) => {
    api
      .post(`/operation/get_meetings`, {
        user_id: user_id,
      })
      .then(({ data }) => {
        dispatch(setMeetings(data?.meetingList || []));
        dispatch(
          setBotFilteredMeetings(filterMeetingsData(data?.meetingList) || [], 2)
        );
        dispatch(
          setDateFilteredMeetings(
            filterMeetingsData(data?.meetingList || [], 2)
          )
        );
      })
      .catch((error) => {
        console.log(error, "Error in getting meetings List");
      });
  };

  const getSupportBot = async (user_id) => {
    api
      .post(`/get_support_bots`, {
        user_id: user_id,
      })
      .then(({ data }) => {
        dispatch(setSupportBot(data?.supportBotList || []));
      })
      .catch((error) => {
        console.log(error, "Error in getting Operation List");
      });
  };

  const getOperationTopic = async (user_id) => {
    api
      .post(`/operation/get_operation`, {
        user_id: user_id,
      })
      .then(({ data }) => {
        dispatch(setTopic(data?.operationTopicList || []));
      })
      .catch((error) => {
        console.log(error, "Error in getting Operation List");
      });
  };

  const getContactsData = async (user_id) => {
    api
      .post(`/contact/get_contacts`, {
        user_id: user_id,
      })
      .then(({ data }) => {
        dispatch(setContactsList(data?.contactList || []));
      })
      .catch((error) => {
        console.log(error, "Error in getting Contacts List");
      });
  };

  const getCallHistoryData = async (user_id) => {
    api
      .post(`/call/get_call_history`, {
        user_id: user_id,
      })
      .then(({ data }) => {
        dispatch(setCallHistoryData(data?.call_history || []));
        dispatch(
          setBotFilteredCallHistory(filterData(data?.call_history || [], 2))
        );
        dispatch(
          setDateFilteredCallHistory(filterData(data?.call_history || [], 2))
        );
      })
      .catch((error) => {
        console.log(error, "callHistory data Error");
      });
  };

  const getMessageCenterData = async (user_id) => {
    api
      .post(`/voicemail/get_voicemails`, {
        user_id: user_id,
      })
      .then(({ data }) => {
        // console.log(data.voicemails, "messageCenter Data");
        dispatch(setMessageCenterData(data?.data || []));
        dispatch(
          setDateFilteredVoiceMails(filterVoiceMailsData(data?.data || []), 2)
        );
        dispatch(
          setBotFilteredVoiceMails(filterVoiceMailsData(data?.data || []), 2)
        );
      })
      .catch((error) => {
        console.log(error, "Error in getting Message center data");
      });
    // console.log(messageData, "Yogi");
  };

  const getBotsList = async (id) => {
    dispatch(setLoading(true));
    api
      .post(`/bots/bot_data`, {
        user_id: id,
      })
      .then(({ data }) => {
        // console.log(data, "list of bots");
        // setBotList(data);
        if (data?.length === 0) {
          navigate("/create-bot");
        }
        // dispatch(setTopic(getTopic(data)));
        dispatch(setLoading(false));
        dispatch(storeBotsList(data));
        // if (data.length === 0) {
        //   navigate("/create-bot");
        //   dispatch(setLoading(false));
        // }
        // if (data.length > 0) {
        //   navigate("/dashboard");
        //   getCallHistoryData(data);
        //   getMessageCenterData(data);
        // }
      });
    dispatch(setLoading(false));
  };

  const getUserDetails = async () => {
    dispatch(setLoading(true));
    // let useremail = localStorage.getItem("userEmail");
    let devOtp = sessionStorage.getItem("devOtp");
    let user_id = sessionStorage.getItem("user_id");
    if (devOtp && user_id) {
      api
        .post(`/auth/get_user_data`, {
          user_id: user_id,
        })
        .then((result) => {
          dispatch(setUserData(result?.data?.user || {}));
          if (
            !result.data.user.subscriptionStatus ||
            result.data.user.subscriptionStatus === "inactive"
          ) {
            dispatch(setLoading(false));
            navigate("/subscription");
          } else {
            getBotsList(result.data.user.id);
            getCallHistoryData(result.data.user.id);
            getMessageCenterData(result.data.user.id);
            getContactsData(result.data.user.id);
            getOperationTopic(result.data.user.id);
            getSupportBot(result.data.user.id);
            getMeetings(result.data.user.id);
          }
        })
        .catch((error) => {
          if (
            error.response.data.success === false &&
            error.response.data.message === "Token Missing"
          ) {
            navigate("/");
          }
          console.log(error, "Error in getting User data");
        });
    } else {
      navigate("/check");
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      {loading ? (
        <CustomModal isOpen={loading}>
          <div className=" flex items-center justify-center">
            <div className=" bg-white p-8 h-fit w-fit rounded-md">
              <Puff
                height="100"
                width="100"
                radius={1}
                color="#FF0066"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
        </CustomModal>
      ) : (
        <div>
          <div className=" ">
            <div className=" flex">
              <div
                className={`hidden lg:block w-1/4 bg-primary sticky top-0 transition-all duration-500 ease-in-out h-screen`}
              >
                <div className="  relative py-4">
                  <div className=" flex items-center justify-center mt-5">
                    <img
                      className=" h-8 w-28"
                      alt="logo"
                      src={require("../assets/images/Logo1.png")}
                    />
                  </div>
                  <div className="px-3 mt-6">
                    {userData.subscriptionStatus === "active" ||
                    userData.subscriptionStatus === "deleted" ? (
                      botsList?.length === 0 ? (
                        <div className="select-none">
                          {/* Rest of the code */}
                          {/* <div
                    className="flex items-center pl-7 rounded-md bg-secoundry my-4 py-2 cursor-pointer"
                    onClick={() => {
                      navigate("/create-bot");
                    }}
                  >
                    <CreateBotIcon color={"#ffff"} />
                    <p className=" mx-4 text-white">Create Bot</p>{" "}
                  </div> */}
                          <div>
                            {MenuBar?.map((item, index) => (
                              <div
                                key={index}
                                onClick={() => {
                                  if (item.title === "Create Bot") {
                                    navigate(`/create-bot`);
                                  } else if (item.title === "Settings") {
                                    navigate(`/settings`);
                                  }
                                }}
                                className={`flex items-center ${
                                  item.path.includes(location.pathname.slice(1))
                                    ? " bg-secoundry text-white"
                                    : "bg-primary text-diselectTab"
                                } my-4 py-2 rounded-md cursor-pointer pl-3`}
                                // className=" my-4 py-2 rounded-md cursor-pointer pl-3 bg-primary text-[#241c51]"
                              >
                                {item.title === "Create Bot" && (
                                  <div className=" flex items-center ml-4">
                                    <CreateBotIcon
                                      color={
                                        item.path.includes(
                                          location.pathname.slice(1)
                                        )
                                          ? "#ffff"
                                          : " rgba(145, 158, 171, 1)"
                                      }
                                    />
                                    <p className="text-[#ffff] mx-4">
                                      {item.title}
                                    </p>{" "}
                                  </div>
                                )}
                                {item.title === "Dashboard" && (
                                  <div className=" flex items-center ml-4 text-[#241c51]">
                                    <DashboardIcon color={"#241c51"} />
                                    <p className=" mx-4">{item.title}</p>{" "}
                                  </div>
                                )}
                                {item.title === "Bots" && (
                                  <div className=" flex items-center ml-4 text-[#241c51]">
                                    <BotsIcon color={"#241c51"} />
                                    <p className=" mx-4">{item.title}</p>{" "}
                                  </div>
                                )}
                                {item.title === "Call History" && (
                                  <div className=" flex items-center ml-4 text-[#241c51]">
                                    <CallHistoryIcon color={"#241c51"} />
                                    <p className=" mx-4">{item.title}</p>{" "}
                                  </div>
                                )}
                                {item.title === "Message Center" && (
                                  <div className=" flex items-center ml-4 text-[#241c51]">
                                    <MessageCenterIcon color={"#241c51"} />
                                    <p className=" mx-4">{item.title}</p>{" "}
                                  </div>
                                )}
                                {item.title === "Contacts" && (
                                  <div className=" flex items-center ml-4 text-[#241c51]">
                                    <ContactsIcon color={"#241c51"} />
                                    <p className=" mx-4">{item.title}</p>{" "}
                                  </div>
                                )}
                                {item.title === "Meetings" && (
                                  <div className=" flex items-center ml-4 text-[#241c51]">
                                    <MeetingsIcon color={"#241c51"} />
                                    <p className=" mx-4">{item.title}</p>{" "}
                                  </div>
                                )}
                                {item.title === "Support Bot" && (
                                  <div className=" flex items-center ml-4 text-[#241c51]">
                                    <SupportBotIcon color={"#241c51"} />
                                    <p className=" mx-4">{item.title}</p>{" "}
                                  </div>
                                )}
                                {item.title === "Settings" && (
                                  <div className=" flex items-center ml-4">
                                    {/* <SettingsIcon color={"#241c51"} /> */}
                                    <SettingsIcon
                                      color={
                                        item.path.includes(
                                          location.pathname.slice(1)
                                        )
                                          ? "#ffff "
                                          : " rgba(145, 158, 171, 1) "
                                      }
                                    />
                                    <p className="text-[#ffff] mx-4">
                                      {item.title}
                                    </p>{" "}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {MenuBar?.map((item, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                navigate(`/${item.path[0]}`);
                                if (item.title === "Settings") {
                                  localStorage.removeItem("payment");
                                }
                              }}
                              className={`flex items-center ${
                                item.path.includes(location.pathname.slice(1))
                                  ? " bg-selectedTab text-white"
                                  : "bg-primary text-diselectTab"
                              } my-4 py-2 rounded-md cursor-pointer pl-3`}
                            >
                              {item.title === "Dashboard" && (
                                <div className=" flex items-center ml-4">
                                  <DashboardIcon
                                    color={
                                      item.path.includes(
                                        location.pathname.slice(1)
                                      )
                                        ? "#ffff"
                                        : " rgba(145, 158, 171, 1)"
                                    }
                                  />
                                  <p className=" mx-4">{item.title}</p>{" "}
                                </div>
                              )}
                              {item.title === "Bots" && (
                                <div className=" flex items-center ml-4">
                                  <BotsIcon
                                    color={
                                      item.path.includes(
                                        location.pathname.slice(1)
                                      )
                                        ? "#ffff"
                                        : " rgba(145, 158, 171, 1)"
                                    }
                                  />
                                  <p className=" mx-4">{item.title}</p>{" "}
                                </div>
                              )}
                              {item.title === "Call History" && (
                                <div className=" flex items-center ml-4">
                                  <CallHistoryIcon
                                    color={
                                      item.path.includes(
                                        location.pathname.slice(1)
                                      )
                                        ? "#ffff"
                                        : " rgba(145, 158, 171, 1)"
                                    }
                                  />
                                  <p className=" mx-4">{item.title}</p>{" "}
                                </div>
                              )}
                              {item.title === "Message Center" && (
                                <div
                                  onClick={() =>
                                    dispatch(
                                      setCallFilteredBot({
                                        _id: 0,
                                        botName: "All",
                                      })
                                    )
                                  }
                                  className=" flex items-center ml-4"
                                >
                                  <MessageCenterIcon
                                    color={
                                      item.path.includes(
                                        location.pathname.slice(1)
                                      )
                                        ? "#ffff"
                                        : " rgba(145, 158, 171, 1)"
                                    }
                                  />
                                  <p className=" mx-4">{item.title}</p>{" "}
                                </div>
                              )}
                              {item.title === "Contacts" && (
                                <div className=" flex items-center ml-4">
                                  <ContactsIcon
                                    color={
                                      item.path.includes(
                                        location.pathname.slice(1)
                                      )
                                        ? "#ffff"
                                        : "rgba(145, 158, 171, 1)"
                                    }
                                  />
                                  <p className=" mx-4">{item.title}</p>{" "}
                                </div>
                              )}
                              {item.title === "Meetings" && (
                                <div className=" flex items-center ml-4">
                                  <MeetingsIcon
                                    color={
                                      item.path.includes(
                                        location.pathname.slice(1)
                                      )
                                        ? "#ffff"
                                        : "rgba(145, 158, 171, 1)"
                                    }
                                  />
                                  <p className=" mx-4">{item.title}</p>{" "}
                                </div>
                              )}
                              {item.title === "Support Bot" && (
                                <div className=" flex items-center ml-4">
                                  <SupportBotIcon
                                    color={
                                      item.path.includes(
                                        location.pathname.slice(1)
                                      )
                                        ? "#ffff"
                                        : "rgba(145, 158, 171, 1)"
                                    }
                                  />
                                  <p className=" mx-4">{item.title}</p>{" "}
                                </div>
                              )}
                              {item.title === "Settings" && (
                                <div className=" flex items-center ml-4">
                                  <SettingsIcon
                                    color={
                                      item.path.includes(
                                        location.pathname.slice(1)
                                      )
                                        ? "#ffff"
                                        : " rgba(145, 158, 171, 1)"
                                    }
                                  />
                                  <p className=" mx-4">{item.title}</p>{" "}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      <>
                        <div
                          onClick={() => {
                            navigate(`/subscription`);
                          }}
                          className={`flex items-center ${
                            "subscription".includes(location.pathname.slice(1))
                              ? " bg-secoundry text-white"
                              : "bg-primary text-diselectTab"
                          } my-4 py-2 rounded-md cursor-pointer pl-3`}
                          // className=" my-4 py-2 rounded-md cursor-pointer pl-3 bg-primary text-[#241c51]"
                        >
                          <div className=" flex items-center ml-4 ">
                            <DashboardIcon color={"#fffff"} />
                            <p className=" mx-4">Subscription</p>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            navigate(`/settings`);
                          }}
                          className={`flex items-center ${
                            "settings".includes(location.pathname.slice(1))
                              ? " bg-secoundry text-white"
                              : "bg-primary text-diselectTab"
                          } my-4 py-2 rounded-md cursor-pointer pl-3`}
                          // className=" my-4 py-2 rounded-md cursor-pointer pl-3 bg-primary text-[#241c51]"
                        >
                          <div className=" flex items-center ml-4 ">
                            <SettingsIcon color={"#ffffff"} />
                            <p className=" mx-4">Settings</p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className=" bg-white w-full">
                <div className=" hidden lg:block sticky top-0 z-20 px-4 bg-white">
                  <div className=" flex flex-row items-center   py-4">
                    <div className=" w-1/2 invisible">
                      <SearchBar />
                    </div>
                    <div className=" flex justify-end w-1/2 items-center">
                      <div className=" mx-4 relative invisible">
                        <HiBell className=" text-3xl text-appgray cursor-pointer" />
                        <p className=" bg-red-500   px-1.5 select-none py-0.5 rounded-full text-center text-white absolute -top-2 text-[10px] -right-2">
                          8
                        </p>
                      </div>
                      <div className=" flex  relative  ">
                        <div className=" mx-2 capitalize flex items-center justify-center bg-[#C2185B] text-white font-semibold text-2xl h-10 w-10 rounded-full">
                          {userData?.firstname !== undefined &&
                            userData?.firstname[0]}
                        </div>
                        <div
                          onClick={() => setMobileProfile(!mobileProfile)}
                          className=" mx-1 cursor-pointer"
                        >
                          <div className="flex items-center">
                            <p className=" capitalize">
                              {`${userData?.firstname} ${userData?.lastname}`}
                            </p>
                            <MdKeyboardArrowDown className=" ml-6" />
                          </div>
                          <p className=" text-sm">{usesDetail}</p>
                        </div>
                        <ProfileDetails
                          isOpen={mobileProfile}
                          userDetails={userData}
                          setIsOpen={setMobileProfile}
                          className=" hidden lg:block right-0 top-0 w-72 bg-white shadow-dropDownBox"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" lg:hidden  sticky top-0 right-0 z-10">
                  <div className=" relative ">
                    <div className=" bg-primary grid grid-cols-3 py-3 px-3">
                      <div className=" flex items-center ">
                        <FiMenu
                          onClick={() => setMenu(true)}
                          className=" text-white text-3xl"
                        />
                        <FiSearch className=" text-white text-xl mx-2 invisible" />
                      </div>
                      <div className=" flex items-center">
                        <img
                          className=" h-8 w-28"
                          alt="logo"
                          src={require("../assets/images/Logo1.png")}
                        />
                      </div>
                      <div className="  flex items-center justify-end">
                        <div className=" mx-4 relative invisible">
                          <HiBell className=" text-3xl text-appgray" />
                          <p className=" bg-red-500   px-1.5 select-none py-0.5 rounded-full text-center text-white absolute -top-2 text-[10px] -right-2">
                            8
                          </p>
                        </div>

                        <div
                          onClick={() => setShowProfile(true)}
                          className=" mx-2 capitalize flex items-center justify-center bg-[#C2185B] text-white font-semibold text-2xl h-10 w-10 rounded-full"
                        >
                          {userData?.firstname !== undefined &&
                            userData?.firstname[0]}
                        </div>
                        <ProfileDetails
                          isOpen={showProfile}
                          userDetails={userData}
                          setIsOpen={setShowProfile}
                          className="lg:hidden right-3 top-3 w-72 bg-white shadow-dropDownBox"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={` transition-all  duration-300 ease-in-out absolute left-0 bg-primary top-0 min-h-screen z-50 ${
                      menu ? " w-9/12" : " w-0"
                    }`}
                  >
                    {menu && (
                      <div>
                        <div className="flex justify-end pt-4 pr-4">
                          <CgClose
                            onClick={() => setMenu(false)}
                            className=" text-white text-2xl"
                          />
                        </div>
                        <div className="flex items-center justify-center mt-8">
                          <img
                            className="h-8 w-28"
                            alt="logo"
                            src={require("../assets/images/Logo1.png")}
                          />
                        </div>
                        <div className=" px-3 mt-6">
                          {userData.subscriptionStatus === "active" ||
                          userData.subscriptionStatus === "deleted" ? (
                            botsList.length === 0 ? (
                              <div>
                                {/* <div className=" flex items-center  pl-7 rounded-md bg-secoundry my-4 py-2">
                          <CreateBotIcon color={"#ffff"} />
                          <p className=" mx-4 text-white">Create Bot</p>{" "}
                        </div> */}
                                <div>
                                  {MenuBar?.map((item, index) => (
                                    <div
                                      key={index}
                                      onClick={() => {
                                        if (item.title === "Create Bot") {
                                          navigate(`/create-bot`);
                                        } else if (item.title === "Settings") {
                                          navigate(`/settings`);
                                        }
                                      }}
                                      className={`flex items-center ${
                                        item.path.includes(
                                          location.pathname.slice(1)
                                        )
                                          ? " bg-secoundry text-white"
                                          : "bg-primary text-diselectTab"
                                      } my-4 py-2 rounded-md cursor-pointer pl-3`}
                                      // className="flex items-center my-4 py-2 rounded-md cursor-pointer pl-3 bg-primary text-[#241c51]"
                                    >
                                      {item.title === "Create Bot" && (
                                        <div className=" flex items-center ml-4">
                                          <CreateBotIcon
                                            color={
                                              item.path.includes(
                                                location.pathname.slice(1)
                                              )
                                                ? "#ffff"
                                                : " rgba(145, 158, 171, 1)"
                                            }
                                          />
                                          <p className=" mx-4">{item.title}</p>{" "}
                                        </div>
                                      )}
                                      {item.title === "Dashboard" && (
                                        <div className=" flex items-center ml-4 text-[#241c51]">
                                          <DashboardIcon color={"#241c51"} />
                                          <p className=" mx-4">
                                            {item.title}
                                          </p>{" "}
                                        </div>
                                      )}
                                      {item.title === "Bots" && (
                                        <div className=" flex items-center ml-4 text-[#241c51]">
                                          <BotsIcon color={"#241c51"} />
                                          <p className=" mx-4">
                                            {item.title}
                                          </p>{" "}
                                        </div>
                                      )}
                                      {item.title === "Call History" && (
                                        <div className=" flex items-center ml-4 text-[#241c51]">
                                          <CallHistoryIcon color={"#241c51"} />
                                          <p className=" mx-4">
                                            {item.title}
                                          </p>{" "}
                                        </div>
                                      )}
                                      {item.title === "Message Center" && (
                                        <div className=" flex items-center ml-4 text-[#241c51]">
                                          <MessageCenterIcon
                                            color={"#241c51"}
                                          />
                                          <p className=" mx-4">{item.title}</p>{" "}
                                        </div>
                                      )}
                                      {item.title === "Contacts" && (
                                        <div className=" flex items-center ml-4 text-[#241c51]">
                                          <ContactsIcon color={"#241c51"} />
                                          <p className=" mx-4">
                                            {item.title}
                                          </p>{" "}
                                        </div>
                                      )}
                                      {item.title === "Meetings" && (
                                        <div className=" flex items-center ml-4 text-[#241c51]">
                                          <MeetingsIcon color={"#241c51"} />
                                          <p className=" mx-4">
                                            {item.title}
                                          </p>{" "}
                                        </div>
                                      )}
                                      {item.title === "Support Bot" && (
                                        <div className=" flex items-center ml-4 text-[#241c51]">
                                          <SupportBotIcon color={"#241c51"} />
                                          <p className=" mx-4">
                                            {item.title}
                                          </p>{" "}
                                        </div>
                                      )}
                                      {item.title === "Settings" && (
                                        <div className=" flex items-center ml-4">
                                          <SettingsIcon
                                            color={
                                              item.path.includes(
                                                location.pathname.slice(1)
                                              )
                                                ? "#ffff "
                                                : " rgba(145, 158, 171, 1) "
                                            }
                                          />
                                          <p className=" mx-4">{item.title}</p>{" "}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <div>
                                {MenuBar?.map((item, index) => (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      navigate(`/${item.path[0]}`);
                                      setMenu(false);
                                    }}
                                    className={`flex items-center ${
                                      item.path.includes(
                                        location.pathname.slice(1)
                                      )
                                        ? " bg-selectedTab text-white"
                                        : "bg-primary text-diselectTab"
                                    } my-4 py-2 rounded-md cursor-pointer pl-3`}
                                  >
                                    {item.title === "Dashboard" && (
                                      <div className=" flex items-center ml-4">
                                        <DashboardIcon
                                          color={
                                            item.path.includes(
                                              location.pathname.slice(1)
                                            )
                                              ? "#ffff"
                                              : " rgba(145, 158, 171, 1)"
                                          }
                                        />
                                        <p className=" mx-4">{item.title}</p>{" "}
                                      </div>
                                    )}
                                    {item.title === "Bots" && (
                                      <div className=" flex items-center ml-4">
                                        <BotsIcon
                                          color={
                                            item.path.includes(
                                              location.pathname.slice(1)
                                            )
                                              ? "#ffff"
                                              : " rgba(145, 158, 171, 1)"
                                          }
                                        />
                                        <p className=" mx-4">{item.title}</p>{" "}
                                      </div>
                                    )}
                                    {item.title === "Call History" && (
                                      <div className=" flex items-center ml-4">
                                        <CallHistoryIcon
                                          color={
                                            item.path.includes(
                                              location.pathname.slice(1)
                                            )
                                              ? "#ffff"
                                              : " rgba(145, 158, 171, 1)"
                                          }
                                        />
                                        <p className=" mx-4">{item.title}</p>{" "}
                                      </div>
                                    )}
                                    {item.title === "Message Center" && (
                                      <div className=" flex items-center ml-4">
                                        <MessageCenterIcon
                                          color={
                                            item.path.includes(
                                              location.pathname.slice(1)
                                            )
                                              ? "#ffff"
                                              : " rgba(145, 158, 171, 1)"
                                          }
                                        />
                                        <p className=" mx-4">{item.title}</p>{" "}
                                      </div>
                                    )}
                                    {item.title === "Contacts" && (
                                      <div className=" flex items-center ml-4">
                                        <ContactsIcon
                                          color={
                                            item.path.includes(
                                              location.pathname.slice(1)
                                            )
                                              ? "#ffff"
                                              : " rgba(145, 158, 171, 1)"
                                          }
                                        />
                                        <p className=" mx-4">{item.title}</p>{" "}
                                      </div>
                                    )}
                                    {item.title === "Meetings" && (
                                      <div className=" flex items-center ml-4">
                                        <MeetingsIcon
                                          color={
                                            item.path.includes(
                                              location.pathname.slice(1)
                                            )
                                              ? "#ffff"
                                              : " rgba(145, 158, 171, 1)"
                                          }
                                        />
                                        <p className=" mx-4">{item.title}</p>{" "}
                                      </div>
                                    )}
                                    {item.title === "Support Bot" && (
                                      <div className=" flex items-center ml-4">
                                        <SupportBotIcon
                                          color={
                                            item.path.includes(
                                              location.pathname.slice(1)
                                            )
                                              ? "#ffff"
                                              : " rgba(145, 158, 171, 1)"
                                          }
                                        />
                                        <p className=" mx-4">{item.title}</p>{" "}
                                      </div>
                                    )}
                                    {item.title === "Settings" && (
                                      <div className=" flex items-center ml-4">
                                        <SettingsIcon
                                          color={
                                            item.path.includes(
                                              location.pathname.slice(1)
                                            )
                                              ? "#ffff"
                                              : " rgba(145, 158, 171, 1)"
                                          }
                                        />
                                        <p className=" mx-4">{item.title}</p>{" "}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )
                          ) : (
                            <>
                              <div
                                onClick={() => {
                                  navigate(`/subscription`);
                                }}
                                className={`flex items-center ${
                                  "subscription".includes(
                                    location.pathname.slice(1)
                                  )
                                    ? " bg-secoundry text-white"
                                    : "bg-primary text-diselectTab"
                                } my-4 py-2 rounded-md cursor-pointer pl-3`}
                                // className=" my-4 py-2 rounded-md cursor-pointer pl-3 bg-primary text-[#241c51]"
                              >
                                <div className=" flex items-center ml-4 ">
                                  <DashboardIcon color={"#fffff"} />
                                  <p className=" mx-4">Subscription</p>
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  navigate(`/settings`);
                                }}
                                className={`flex items-center ${
                                  "settings".includes(
                                    location.pathname.slice(1)
                                  )
                                    ? " bg-secoundry text-white"
                                    : "bg-primary text-diselectTab"
                                } my-4 py-2 rounded-md cursor-pointer pl-3`}
                                // className=" my-4 py-2 rounded-md cursor-pointer pl-3 bg-primary text-[#241c51]"
                              >
                                <div className=" flex items-center ml-4 ">
                                  <SettingsIcon color={"#ffffff"} />
                                  <p className=" mx-4">Settings</p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="bg-white">
                  <HomeRoutes />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Layouts;
