import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { BsCheck, BsThreeDotsVertical } from "react-icons/bs";
import {
  MdCheckBox,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import SupportBotCard from "./SupportBotCard";
import { PiNotepad } from "react-icons/pi";

const SupportBotTable = ({
  listOfSupportBots,
  setShowModel,
  setSelectedOption,
  selectedSupportBots,
  setSelectedSupportBots,
  setCreateSupportBotData,
  selectAll,
  setSelectAll,
}) => {
  const [toggleSupportBotId, setToggleSupportBotId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [supportBotsPerPage, setSupportBotsPerPage] = useState(10);
  const indexOfLastSupportBot = currentPage * supportBotsPerPage;
  const indexOfFirstSupportBot = indexOfLastSupportBot - supportBotsPerPage;
  const currentTopics = listOfSupportBots.slice(
    indexOfFirstSupportBot,
    indexOfLastSupportBot
  );
  const totalPages = Math.ceil(listOfSupportBots.length / supportBotsPerPage);
  // const [selectAll, setSelectAll] = useState(false);

  const toggleIndividualSelected = (topicId) => {
    const updatedSelectedSupportBots = [...selectedSupportBots];
    const index = updatedSelectedSupportBots.indexOf(topicId);

    if (index === -1) {
      updatedSelectedSupportBots.push(topicId);
    } else {
      updatedSelectedSupportBots.splice(index, 1);
    }

    setSelectedSupportBots(updatedSelectedSupportBots);
  };

  const toggleSelectAll = () => {
    const allTopicIds = currentTopics.map((topic) => topic._id);
    if (selectedSupportBots.length === allTopicIds.length) {
      setSelectedSupportBots([]);
      setSelectAll(false);
    } else {
      setSelectedSupportBots(allTopicIds);
      setSelectAll(true);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleContactsPerPageChange = (e) => {
    setSupportBotsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  // to handle mobile view
  const [allOptions] = useState(["Edit", "Delete"]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const handleOptionClick = (option, item) => {
    setSelectedOption(option);
    setIsOpen(false);
    setCreateSupportBotData(item);
    setSelectedSupportBots([item._id]);
    setShowModel(true);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <ToastContainer />
      <div className=" hidden md:block relative bg-white">
        <div className=" grid grid-cols-6 xl:gap-x-12  bg-secoundry py-2  pl-2 rounded-md mt-2 mx-5">
          <div
            className="w-1/4 text-[24px] cursor-pointer pl-5"
            onClick={toggleSelectAll}
          >
            {selectAll ? (
              <BsCheck className="text-white bg-primary rounded" />
            ) : (
              <MdCheckBoxOutlineBlank className=" text-white bg-white border-secoundry border-2 rounded " />
            )}
          </div>
          <p className=" text-[14.86px] text-white font-semibold">Topic</p>
          <p className="col-span-2 text-[14.86px] text-white font-semibold">
            Description
          </p>
          <p className="text-[14.86px] text-white font-semibold">Knowledge</p>
          <p className=" text-[14.86px] text-white"></p>
          <p className=" text-[14.86px] text-white ml-5"></p>
          <p className=" text-[14.86px] text-white ml-5"></p>
        </div>
      </div>
      <div className=" hidden md:block mx-3 static h-[64vh] overflow-auto">
        {currentTopics.length === 0 ? (
          <div className=" flex items-center justify-center py-5">
            <p className=" text-appgray">You Have No Any Support Bot</p>
          </div>
        ) : (
          <div className=" my-3">
            {currentTopics.map((item, index) => (
              <SupportBotCard
                key={item._id}
                item={item}
                index={index}
                setShowModel={setShowModel}
                selectedSupportBots={selectedSupportBots}
                setSelectedSupportBots={setSelectedSupportBots}
                setCreateSupportBotData={setCreateSupportBotData}
                indexOfFirstSupportBot={indexOfFirstSupportBot}
                toggleIndividualSelected={toggleIndividualSelected}
                setSelectedOption={setSelectedOption}
                toggleSupportBotId={toggleSupportBotId}
                setToggleSupportBotId={setToggleSupportBotId}
              />
            ))}
          </div>
        )}
      </div>
      <hr />
      <div className="hidden md:flex justify-between mt-2 mx-2">
        <div></div>
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <p className="-mt-1">Rows per page:</p>
          </div>
          <div className="mb-1">
            <select
              value={supportBotsPerPage}
              onChange={handleContactsPerPageChange}
              className="px-2 py-1 border border-gray-100 rounded-md bg-white"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <p className="-mt-1">
            {currentPage} of {totalPages}
          </p>
          <div className="flex gap-6 -mt-1">
            <button
              disabled={currentPage === 1}
              onClick={handlePrevPage}
              className={`text-2xl cursor-pointer ${
                currentPage === 1 ? "text-gray-400" : ""
              }`}
            >
              <MdKeyboardArrowLeft />
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
              className={`text-2xl cursor-pointer ${
                currentPage === totalPages ? "text-gray-400" : ""
              }`}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className="md:hidden block w-full bg-white overflow-x-auto">
        <table className="table-auto w-[794px] border-separate rounded-t-lg -mt-2 border-spacing-y-3">
          <thead className="bg-secoundry rounded-lg">
            <tr className="text-[16px] text-white h-10">
              <th onClick={toggleSelectAll} className="pl-2">
                {selectAll ? (
                  <BsCheck className="text-white bg-primary rounded" />
                ) : (
                  <MdCheckBoxOutlineBlank className=" text-white bg-white border-secoundry border-2 rounded " />
                )}
              </th>
              <th className="">Topic</th>
              <th className="text-start">Description</th>
              <th>knowledge</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {currentTopics.length === 0 ? (
            <div className=" flex items-center justify-center py-5">
              <p className=" text-appgray">You Have No Any Meeting Topic</p>
            </div>
          ) : (
            <tbody>
              {currentTopics.map((item, index) => (
                <tr
                  key={item._id}
                  className={`rounded-lg text-[16px] text-ttt font-Poppins font-normal h-8  ${
                    selectedSupportBots.includes(item._id)
                      ? "bg-slate-100"
                      : "hover:bg-slate-200"
                  }`}
                >
                  <td
                    onClick={() => toggleIndividualSelected(item._id)}
                    className="pl-2"
                  >
                    {selectedSupportBots.includes(item._id) ? (
                      <MdCheckBox className=" text-secoundry text-xl mr-1 mt-1" />
                    ) : (
                      <p className="pl-2">{indexOfFirstSupportBot + index + 1}</p>
                    )}
                  </td>
                  <td className="text-center px-6">{item?.topic}</td>
                  <td className="text-start">{item?.description}</td>

                  <td className="text-2xl pl-10 text-center">
                    <PiNotepad
                      title={item?.knowledge}
                      className="cursor-pointer"
                    />
                  </td>
                  {/* <td ref={ref} className="relative pr-2 px-4"> */}
                  <td className="relative pr-2 px-4">
                    <button
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setToggleSupportBotId(item._id);
                      }}
                    >
                      <BsThreeDotsVertical className={""} />
                    </button>
                    {isOpen && toggleSupportBotId === item._id && (
                      <div
                        className="relative inset-0 flex items-center justify-center z-50"
                        onClick={() => setIsOpen(false)}
                      >
                        <div
                          className="absolute bg-white shadow-dropDownBox rounded-lg overflow-hidden right-6"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ul>
                            {allOptions.map((option, index) => (
                              <li
                                onClick={() => handleOptionClick(option, item)}
                                className="cursor-pointer py-1 px-2 transition-all rounded-lg duration-300 ease-in-out hover:bg-primary hover:text-white"
                                key={index}
                              >
                                {option}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <hr />
      <div className="md:hidden mt-2 mr-2 bg-gray-50">
        <div className="flex justify-between gap-2 items-center">
          <div className="flex justify-between ">
            <div className="w-32">
              <p className="ml-1 mt-1 text-[14.88px]">Rows per page:</p>
            </div>
            <div className="mb-1 ">
              <select
                value={supportBotsPerPage}
                onChange={handleContactsPerPageChange}
                className="py-1 border border-gray-100 rounded-md bg-white"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="flex gap-2">
            <p className="-mt-1">
              {currentPage} of {totalPages}
            </p>
            <div className="flex gap-3 -mt-1">
              <button
                disabled={currentPage === 1}
                onClick={handlePrevPage}
                className={`bg-white text-2xl cursor-pointer ${
                  currentPage === 1 ? "text-gray-400" : ""
                }`}
              >
                <MdKeyboardArrowLeft />
              </button>
              <button
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                className={`bg-white text-2xl cursor-pointer ${
                  currentPage === totalPages ? "text-gray-400" : ""
                }`}
              >
                <MdKeyboardArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="  lg:hidden mx-3  ">
        {listOfSupportBots?.length === 0 ? (
          <div className=" flex items-center justify-center py-5">
            <p className=" text-appgray">You Have No Any Meeting topic</p>
          </div>
        ) : (
          <div>
            {listOfSupportBots?.map((item, index) => {
              const handleDelete = () => {
                setSelectedOption("Delete");
                setShowModel(true);
                setSelectedSupportBots([item._id]);
              };

              const handleEdit = () => {
                setSelectedOption("Edit");
                setShowModel(true);
                setCreateSupportBotData(item);
              };

              const handleBlock = () => {
                setSelectedOption("block");
                setShowModel(true);
                setSelectedSupportBots([item._id]);
              };

              const handleUnblock = () => {
                setSelectedOption("unBlock");
                setShowModel(true);
                setSelectedSupportBots([item._id]);
              };

              return (
                <div key={item._id}>
                  <MeetingListCard
                    item={item}
                    index={index}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    onBlock={handleBlock}
                    onUnBlock={handleUnblock}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div> */}
    </>
  );
};

export default SupportBotTable;
