import React, { useEffect, useState } from "react";
import { FiInfo } from "react-icons/fi";

const InputFieldM = ({
  title,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  value,
  className,
  titleClassName,
  maxLength,
  info,
  touched,
  errors,
}) => {
  const [charCount, setCharCount] = useState(value?.length);

  useEffect(() => {
    // Update charCount when the component mounts or when the value prop changes
    setCharCount(value?.length);
  }, [value]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setCharCount(inputValue?.length);
    onChange(event);
  };

  return (
    <div className="relative w-full mt-5">
      <input
        placeholder={placeholder}
        type="text"
        onChange={handleInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        className={`outline-none bg-[#F8F8F8] text-sm border-2 font-thin ${
          touched && errors ? "border-red-800" : "border-border"
        } rounded-md h-[48px] w-full pl-3 caret-slate-600 transition-all duration-500 ease-in-out focus:border-pink-300 ${className}`}
      />
      <h1
        className={`${titleClassName} bg-white absolute -top-7 text-primary font-thin text-base px-1`}
      >
        {title}
      </h1>
      {/* {maxLength ? <div className="absolute bottom-4 right-3 text-xs text-gray-500">
        {charCount}/{maxLength}
      </div> : ""} */}
      <div className="tooltip-container absolute bottom-4 right-3 text-xl text-gray-500">
        <FiInfo />
        <span
          className="md:hidden tooltip-text invisible bg-white text-primary text-center shadow-card rounded py-1 px-3 absolute z-10 opacity-0 transition-opacity duration-300"
          style={{ top: '125%', left: '50%', transform: 'translateX(-90%)' }}
        >{info}</span>
        <span className="hidden md:inline tooltip-text shadow-card">{info}</span>
      </div>
    </div>
  );
};

export default InputFieldM;
