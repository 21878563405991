// import dayjs from "dayjs";

// export const filterDataa = (
//   inputData,
//   range,
//   choosedDate,
//   choosedDateE,
//   dashboardData
// ) => {
//   const currentDate = dayjs();
//   let filteredData = [];
//   if (range === 0) {
//     filteredData = dashboardData;
//     return filteredData;
//   } else if (range === 1) {
//     filteredData = inputData.filter((data) => {
//       const filterDate = dayjs(data.date);
//       const diffInDays = currentDate.diff(filterDate, "day");
//       return diffInDays <= 7;
//     });
//   } else if (range === 2) {
//     filteredData = inputData.filter((data) => {
//       const filterDate = dayjs(data.date);
//       const diffInMonths = currentDate.diff(filterDate, "month");
//       return diffInMonths <= 1;
//     });
//   } else if (range === 3) {
//     filteredData = inputData.filter((data) => {
//       const filterDate = dayjs(data.date);
//       const diffInMonths = currentDate.diff(filterDate, "month");
//       return diffInMonths <= 6;
//     });
//   } else if (range === 4) {
//     filteredData = inputData.filter(
//       (data) => data.date >= choosedDate && data.date <= choosedDateE
//     );
//     return filteredData;
//   }
//   console.log(filteredData);
//   return filteredData;
// };

export function filterData(
  callHistory,
  range,
  startDate,
  endDate,
  dashboardData
) {
  const currentDate = new Date();
  const filteredHistory = callHistory.filter((call) => {
    const callDate = new Date(call.date);
    switch (range) {
      case 0: // All time data
        const allData = dashboardData;
        return allData;
      case 1: // Last 7 days including today
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        return callDate >= sevenDaysAgo && callDate <= currentDate;
      case 2: // Current month
        const currentMonthStart = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const nextMonthStart = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        );
        nextMonthStart.setHours(0, 0, 0, 0); // Set to the beginning of the next month
        return callDate >= currentMonthStart && callDate < nextMonthStart;
      case 3: // Last 6 months including current month
        const sixMonthsAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 5,
          1
        );
        sixMonthsAgo.setHours(0, 0, 0, 0);
        return callDate >= sixMonthsAgo && callDate <= currentDate;
      case 4: // Custom range between startDate and endDate
        const rangeStartDate = new Date(startDate);
        const rangeEndDate = new Date(endDate);
        return callDate >= rangeStartDate && callDate <= rangeEndDate;
      default:
        return [];
    }
  });
  return filteredHistory;
}

export function filterMeetingsData(
  meetings,
  range,
  startDate,
  endDate,
  dashboardData
) {
  const currentDate = new Date();
  const filteredHistory = meetings.filter((meeting) => {
    const callDate = new Date(meeting.date);
    switch (range) {
      case 0: // All time data
        const allData = dashboardData;
        return allData;
      case 1: // Last 7 days including today
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        return callDate >= sevenDaysAgo && callDate <= currentDate;
      case 2: // Current month
        const currentMonthStart = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const nextMonthStart = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        );
        nextMonthStart.setHours(0, 0, 0, 0); // Set to the beginning of the next month
        return callDate >= currentMonthStart && callDate < nextMonthStart;
      case 3: // Last 6 months including current month
        const sixMonthsAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 5,
          1
        );
        sixMonthsAgo.setHours(0, 0, 0, 0);
        return callDate >= sixMonthsAgo && callDate <= currentDate;
      case 4: // Custom range between startDate and endDate
        const rangeStartDate = new Date(startDate);
        const rangeEndDate = new Date(endDate);
        return callDate >= rangeStartDate && callDate <= rangeEndDate;
      default:
        return [];
    }
  });
  return filteredHistory;
}

export function filterVoiceMailsData(
  voiceMails,
  range,
  startDate,
  endDate,
  dashboardData
) {
  const currentDate = new Date();
  const filteredVoiceMail = voiceMails.filter((voiceMail) => {
    const voiceMailDate = new Date(voiceMail.date);
    switch (range) {
      case 0: // All time data
        const allData = dashboardData;
        return allData;
      case 1: // Last 7 days including today
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        return voiceMailDate >= sevenDaysAgo && voiceMailDate <= currentDate;
      case 2: // Current month
        const currentMonthStart = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const nextMonthStart = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        );
        nextMonthStart.setHours(0, 0, 0, 0); // Set to the beginning of the next month
        return (
          voiceMailDate >= currentMonthStart && voiceMailDate < nextMonthStart
        );
      case 3: // Last 6 months including current month
        const sixMonthsAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 5,
          1
        );
        sixMonthsAgo.setHours(0, 0, 0, 0);
        return voiceMailDate >= sixMonthsAgo && voiceMailDate <= currentDate;
      case 4: // Custom range between startDate and endDate
        const rangeStartDate = new Date(startDate);
        const rangeEndDate = new Date(endDate);
        return voiceMailDate >= rangeStartDate && voiceMailDate <= rangeEndDate;
      default:
        return [];
    }
  });
  return filteredVoiceMail;
}
