import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaSort } from "react-icons/fa";
import { convertSecondsToHMS } from "../utils/helper/helperFunctions";
import SelectBot from "../components/dashboard/SelectBot";
import CustomAreaChart from "../components/dashboard/CustomAreaChart";
import DatePicker from "../components/dashboard/DatePicker";
import {
  callDuration,
  countHumanAnsweredCalls,
  avgCallDuration,
  getNoOfMessages,
  countBranches,
  countByCountry,
  countFAQs,
} from "../utils/helper/dashboardFunctions";
import {
  setBotFilteredCallHistory,
  setDateFilteredCallHistory,
  setDashboardFilteredBot,
  setGraphType,
  setDayFilterType,
  setBotFilteredMeetings,
  setDateFilteredMeetings,
  setDateFilteredVoiceMails,
  setBotFilteredVoiceMails,
} from "../redux/DashboardSlice";
import {
  filterData,
  filterMeetingsData,
  filterVoiceMailsData,
} from "../utils/helper/dashboardFilterData";
import GeographyChart from "../components/dashboard/GeographyChart";
import { RiArrowUpSLine } from "react-icons/ri";
import { getCallHistoryStatus } from "../utils/helper/dashboardChartFunctions";
// import { getCallHistoryStatus } from "../utils/helper/dashboardChartFunctions";
const { getName } = require("country-list");

const Dashboard = () => {
  const listOfBots = useSelector((state) => state.app.botsList);
  const userData = useSelector((state) => state.app.userData);
  const callHistory = useSelector((state) => state.app.callHistory);
  const meetings = useSelector((state) => state.app.meetings);
  const messageCenter = useSelector((state) => state.app.messageCenter);
  const dashboardFilteredBot = useSelector(
    (state) => state.dashboard.dashboardFilteredBot
  );
  const allBotReClick = useSelector((state) => state.dashboard.allBotReClick);
  const botFilteredCallHistory = useSelector(
    (state) => state.dashboard.botFilteredCallHistory
  );
  const botFilteredMeetings = useSelector(
    (state) => state.dashboard.botFilteredMeetings
  );
  const botFilteredVoiceMails = useSelector(
    (state) => state.dashboard.botFilteredVoiceMails
  );
  const dateFilteredCallHistory = useSelector(
    (state) => state.dashboard.dateFilteredCallHistory
  );
  const dateFilteredMeetings = useSelector(
    (state) => state.dashboard.dateFilteredMeetings
  );
  const dateFilteredVoiceMails = useSelector(
    (state) => state.dashboard.dateFilteredVoiceMails
  );
  const dayFilterType = useSelector((state) => state.dashboard.dayFilterType);
  // const callFilterData = useSelector((state) => state.dashboard.callFilterData);
  const dashboardData = callHistory?.flat() || [];
  const [dayFilter, setDayFilter] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [choosedDate, setChoosedDate] = useState("2011-01-01 01:48");
  const [choosedDateE, setChoosedDateE] = useState("2011-01-01 01:48");
  // this multiBot state is used in top branch function
  const [multiBot, setMultiBot] = useState(true);
  // const [custom, setCustom] = useState(0);
  const [sortFAQs, setSortFAQs] = useState(true);
  const [sortCountry, setSortCountry] = useState(true);
  const [sortBranch, setSortBranch] = useState(true);

  const dispatch = useDispatch();

  // function to bot filter
  function botFilter() {
    if (dashboardFilteredBot?._id !== 0) {
      let botFilterCalls = dateFilteredCallHistory?.filter(
        (call) => call?.bot_id === dashboardFilteredBot?._id
      );
      let botFilterMeetings = dateFilteredMeetings?.filter(
        (meeting) => meeting?.bot_id === dashboardFilteredBot?._id
      );
      let botFilterVoiceMails = dateFilteredVoiceMails?.filter(
        (voiceMail) => voiceMail?.bot_id === dashboardFilteredBot?._id
      );
      dispatch(setBotFilteredCallHistory(botFilterCalls));
      dispatch(setBotFilteredMeetings(botFilterMeetings));
      dispatch(setBotFilteredVoiceMails(botFilterVoiceMails));
      //dispatch(setDateFilteredCallHistory(botFilter));
      setMultiBot(false);
    } else {
      dispatch(setBotFilteredCallHistory(dateFilteredCallHistory));
      dispatch(setBotFilteredMeetings(dateFilteredMeetings));
      dispatch(setBotFilteredVoiceMails(dateFilteredVoiceMails));
      //dispatch(setDateFilteredCallHistory(dashboardData));
      if (listOfBots.length > 1) {
        setMultiBot(true);
      }
    }
  }

  const AllBotsActivityData = [
    {
      title: "Total Calls Received",
      number:
        (botFilteredCallHistory?.length || 0) -
        countHumanAnsweredCalls(botFilteredCallHistory, "WhatsApp"),
    },
    {
      title: "Avg. Call Duration",
      number: avgCallDuration(botFilteredCallHistory),
    },
    {
      title: "Total Call Duration",
      number: convertSecondsToHMS(
        Math.round(callDuration(botFilteredCallHistory))
      ),
    },
    {
      title: "Human Answered Calls",
      number: countHumanAnsweredCalls(
        botFilteredCallHistory,
        "Transferred to Human"
      ),
    },
    {
      title: "No. of Message in Message Center",
      number: getNoOfMessages(botFilteredVoiceMails),
    },
    {
      title: "No. of WhatsApp conversations",
      number: countHumanAnsweredCalls(botFilteredCallHistory, "WhatsApp"),
    },
    {
      title: "Number of Meetings",
      number: botFilteredMeetings?.length || 0,
    },
    {
      title: "Total Active Bots",
      number:
        listOfBots?.filter((obj) => obj?.status === "active")?.length || 0,
    },
  ];

  const country = () => {
    if (sortCountry) {
      return countByCountry(botFilteredCallHistory)?.sort(
        (a, b) => b?.value - a?.value
      );
    } else {
      return countByCountry(botFilteredCallHistory)?.sort(
        (a, b) => a?.value - b?.value
      );
    }
  };
  const topFAQs = () => {
    if (sortFAQs) {
      return countFAQs(botFilteredCallHistory)?.sort(
        (a, b) => b?.frequency - a?.frequency
      );
    } else {
      return countFAQs(botFilteredCallHistory)?.sort(
        (a, b) => a?.frequency - b?.frequency
      );
    }
  };
  const branches = () => {
    if (sortBranch) {
      return countBranches(botFilteredCallHistory, multiBot)?.sort(
        (a, b) => b?.numberOfCalls - a?.numberOfCalls
      );
    } else {
      return countBranches(botFilteredCallHistory, multiBot)?.sort(
        (a, b) => a?.numberOfCalls - b?.numberOfCalls
      );
    }
  };

  // console.log(country, "this is top faqs");
  // const branches = branchCount(botFilteredCallHistory);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  let data = ["All Time", "7 Days", "1 month", "6 months", "Customized "];
  const currentDate = new Date();
  const sixMonthsAgo = new Date(currentDate);
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  sixMonthsAgo.setDate(1);

  const dayHandler = (value) => {
    setDayFilter(value);
    if (value === 0) {
      dispatch(setDashboardFilteredBot({ _id: 0, botName: "All" }));
      dispatch(
        setGraphType(
          getCallHistoryStatus(userData?.createdAt, currentDate?.toJSON())
        )
      );
    } else if (value === 1) {
      dispatch(setGraphType({ id: 0 }));
    } else if (value === 2) {
      dispatch(setGraphType({ id: 1 }));
    } else if (value === 3) {
      dispatch(
        setGraphType(
          getCallHistoryStatus(sixMonthsAgo?.toJSON(), currentDate?.toJSON())
        )
      );
    } else if (value === 4) {
      setShowDatePicker(true);
    }
    setIsOpen(false);
    if (value !== 4) {
      dispatch(
        setDateFilteredCallHistory(
          filterData(
            dashboardData,
            value,
            choosedDate,
            choosedDateE,
            dashboardData
          )
        )
      );
      dispatch(
        setDateFilteredMeetings(
          filterMeetingsData(
            meetings,
            value,
            choosedDate,
            choosedDateE,
            meetings
          )
        )
      );
      dispatch(
        setDateFilteredVoiceMails(
          filterVoiceMailsData(
            messageCenter,
            value,
            choosedDate,
            choosedDateE,
            messageCenter
          )
        )
      );
    }

    setSelectedFilter(value);
  };

  // useEffect(() => {
  //   if (selectedFilter === 4) {
  //     dispatch(
  //       setDateFilteredCallHistory(
  //         filterData(
  //           dashboardData,
  //           dayFilterType,
  //           choosedDate,
  //           choosedDateE,
  //           dashboardData
  //         )
  //       )
  //     );
  //   }
  //   setCoustum(coustum + 1);
  //   console.log("first useEffect called");
  // }, [callFilterData]);

  // useEffect(() => {
  //   if (selectedFilter === 4) {
  //     dispatch(setGraphType(getCallHistoryStatus(choosedDate, choosedDateE)));
  //     console.log("second useEffect called");
  //   }
  //   console.log("second useEffect called without 4");
  // }, [coustum]);

  useEffect(() => {
    botFilter();
  }, [
    dashboardFilteredBot,
    allBotReClick,
    dateFilteredCallHistory,
    dayFilterType,
  ]);

  return (
    <div className=" px-4 py-4">
      <span
        onClick={() => {
          setIsOpen(false);
        }}
        className=" text-primary font-bold text-2xl"
      >
        Welcome Back,<span className=" capitalize"> {userData?.firstname}</span>
      </span>
      <div className=" flex justify-between items-center my-4">
        {showDatePicker && (
          <DatePicker
            setShowDatePickerC={setShowDatePicker}
            setChoosedDateC={setChoosedDate}
            setChoosedDateD={setChoosedDateE}
          />
        )}
        {/* new date filter started */}
        <div className="flex gap-3">
          <div>
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className=" flex items-center justify-start  text-sm font-medium text-primary w-32 py-0.5 rounded-md"
            >
              <p>{dayFilter === "" ? data[dayFilterType] : data[dayFilter]}</p>
              <RiArrowUpSLine
                className={`ml-2 ${isOpen ? "rotate-0" : "rotate-180"}`}
              />
            </button>
            <div
              className={`absolute bg-white shadow-dropDownBox  rounded-lg overflow-hidden`}
            >
              {isOpen && (
                <ul>
                  <>
                    {data?.map((option, index) => (
                      <li
                        onClick={() => {
                          dayHandler(index);
                          dispatch(setDayFilterType(index));
                        }}
                        className={`cursor-pointer py-1 px-2 transition-all rounded-lg duration-300 ease-in-out hover:bg-primary hover:text-white `}
                        key={index}
                      >
                        {option}
                      </li>
                    ))}
                  </>
                </ul>
              )}
            </div>
          </div>
          {selectedFilter === 4 &&
          choosedDate !== "2011-01-01 01:48" &&
          choosedDateE !== "2011-01-01 01:48" ? (
            <div className="hidden md:block">
              {choosedDate?.slice(0, -5)}/{choosedDateE?.slice(0, -5)}
            </div>
          ) : (
            ""
          )}
        </div>
        {/* new date filter ended */}

        <SelectBot itemClassName=" w-32" />
      </div>
      <div
        onClick={() => {
          setIsOpen(false);
        }}
        className=" border-2 border-gray-200  py-5 px-2 my-4 rounded-md "
      >
        <div className=" grid grid-cols-1  md:grid-cols-3 gap-4 lg:gap-0   lg:grid-cols-4 ">
          {AllBotsActivityData?.map((item, index) => (
            <div
              key={index}
              className={` px-5 py-3 rounded-md shadow-dropDownBox md:shadow-none ${
                index !== AllBotsActivityData.length - 1 &&
                "border-r-2 border-gray-200"
              }`}
            >
              <h2 className=" font-medium text-primary">{item.title}</h2>
              <h1 className=" font-bold text-xl text-secoundry ">
                {/* {item.title === "Total Calls Received"
                  ? `${callHistory?.flat(1)?.length}`
                  : item.title === "Avg. Call Duration"
                  ? `${item.number}`
                  : item.title === "Total Call Duration"
                  ? `${convertSecondsToHMS(
                      Math.round(callDuration(callHistory))
                    )}`
                  : item.title === "Human Answered Calls"
                  ? `${countHumanAnsweredCalls(callHistory?.flat())}`
                  : item.title === "Number of Message"
                  ? `${`${messageCenter?.flat()?.length}`}`
                  : `${item.number}`} */}
                {item?.number}
              </h1>
              <span
                className={`text-[13px] invisible ${
                  item.progress < 0 ? "text-red-600" : "text-green-500"
                }`}
              >
                {item.progress > 0 ? `+${item.progress}` : item.progress}
                <span className=" text-gray-800 mx-1">From last month</span>
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className=" pt-3">
        <h3 className=" text-primary font-medium mb-6 ">
          Total Calls Received
        </h3>
        <CustomAreaChart />
      </div>
      <div className=" my-6 lg:flex  ">
        <div className=" lg:w-[40%]   p-2">
          <div className=" border border-gray-300 rounded-md py-8">
            <p className=" font-semibold text-primary text-lg  ml-5">
              Top Branch
            </p>
            <div className=" mx-3 px-5 mt-2 font-normal text-sm py-2 rounded-md bg-primary text-white flex items-center justify-between">
              <p>Branch Name</p>
              <div className="flex items-center justify-start gap-2">
                <p>Calls Received</p>{" "}
                <FaSort
                  className="cursor-pointer"
                  onClick={() => setSortBranch(!sortBranch)}
                />
              </div>
            </div>
            <div className=" flex items-center justify-between px-8">
              <div>
                {branches()?.map((item, index) => (
                  <div className=" my-3" key={index}>
                    {item?.branch}
                  </div>
                ))}
              </div>
              <div>
                {branches()?.map((item, index) => (
                  <div key={index} className=" my-3">
                    {item?.numberOfCalls}
                  </div>
                ))}
              </div>
            </div>

            {/* {callData?.map((item, index) => (numberOfCalls
              <div
                key={index}
                className=" mx-3 px-5 my-3 font-normal text-sm py-2 rounded-md text-primary flex items-center justify-between"
              >
                <p>{item.branchName}</p>
                <p className=" mr-9">{item.totalCalls}</p>
              </div>
            ))} */}
          </div>
        </div>
        <div className=" lg:w-[60%]  p-2">
          <div className=" border border-gray-300 rounded-md px-3 py-8">
            <p className=" font-semibold text-primary text-lg  ml-2">
              Geographic Data
            </p>
            <div className=" lg:flex mt-2">
              <div className=" lg:w-[65%]">
                {/* <img alt="map" src={require("../assets/images/map.png")} /> */}
                <div className="hidden lg:block h-96 overflow-hidden ">
                  <GeographyChart
                    isDashboard={true}
                    data={country()}
                    maxNumber={botFilteredCallHistory?.length}
                  />
                </div>
                <div className="lg:hidden h-72 overflow-hidden ">
                  <GeographyChart
                    isDashboard={true}
                    data={country()}
                    maxNumber={botFilteredCallHistory?.length}
                    isMobile={true}
                  />
                </div>
                {/* <GeographyChart isDashboard={true} /> */}
                <div className=" flex items-center my-4 lg:my-0 lg:mt-1">
                  <p className=" text-sm mx-1 text-primary">1</p>
                  <div className=" bg-gradient-to-r from-blue-100 to-blue-700 w-36 h-5" />
                  <p className=" text-sm mx-1 text-primary">
                    {Math.ceil(botFilteredCallHistory?.length / 10) * 10}
                  </p>
                </div>
              </div>
              <div className=" lg:w-[35%]">
                <p className=" font-medium text-primary text-[16px]">
                  Countries
                </p>
                <div className=" lg:mr-12">
                  {country()?.map((item, index) => (
                    <div
                      key={index}
                      className=" flex items-center justify-between my-2"
                    >
                      <p className=" text-[15px] text-primary font-normal">
                        {getName(item.id)}
                      </p>
                      <p className=" text-[15px] text-primary font-normal">
                        {item.value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" p-3">
        <div className=" border border-gray-300 rounded-md px-5 py-8">
          <p className="  font-medium text-primary ">Top FAQs</p>
          <div className=" bg-primary text-white flex items-center justify-between px-5 py-2 mt-4 rounded-md">
            <p>Question</p>
            <div className="flex items-center justify-start gap-2">
              <p>Frequency</p>{" "}
              <FaSort
                className=" cursor-pointer"
                onClick={() => {
                  setSortFAQs(!sortFAQs);
                }}
              />
            </div>
          </div>
          <div className=" mt-2">
            {topFAQs()?.map((item, index) => (
              <div
                key={index}
                className="  text-primary flex items-center justify-between px-5 py-2"
              >
                <p>{item?.question}</p>
                <p>{item?.frequency}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
