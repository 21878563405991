import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PaddleLoader } from "../components/PaddleLoader";
import { setUserData } from "../redux/AppSlice";
const basicPlanId = process.env.REACT_APP_PlanID_Basic;
const advancedPlanId = process.env.REACT_APP_PlanID_Advanced;
const expertPlanId = process.env.REACT_APP_PlanID_Expert;

const Plan = (props) => {
  const {
    planType,
    planInfo,
    planPrice,
    planMin,
    planRate,
    planButton,
    btnStyle,
    boxStyle,
    recStyle,
    noOfBots,
    newBotFixCharge,
    callHistoryValidity,
    messageCenter,
  } = props;
  return (
    <>
      <div className={`${boxStyle} rounded-xl relative`}>
        <div
          className={`${recStyle} bg-[#FFECEB]/[0.2] rounded-md items-end absolute top-2 right-2`}
        >
          <p className={`text-[10px] text-[#FF0000] font-medium px-2 py-1`}>
            Recommended
          </p>
        </div>
        <h5 className="mt-[30px] mb-[11px]">{planType}</h5>
        <p className="text-[13px] text-[#7F7F7F] font-normal mb-[20px]">
          {planInfo}
        </p>
        <h3 className="text-[56px] font-semibold text-[#FF0066] mb-[20px]">
          {planPrice}
        </h3>
        <div className="">
          <div className="flex gap-2 mb-3">
            <p className="rounded-[100%] bg-[#E2ECFF]">
              <span className="px-1 text-primary">✓</span>
            </p>
            <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
              {planMin}
            </p>
          </div>
          <div className="flex gap-2 mb-3">
            <p className="rounded-[100%] bg-[#E2ECFF]">
              <span className="px-1 text-primary">✓</span>
            </p>
            <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
              {planRate}
            </p>
          </div>
          <div className="flex gap-2 mb-3">
            <p className="rounded-[100%] bg-[#E2ECFF]">
              <span className="px-1 text-primary">✓</span>
            </p>
            <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
              {noOfBots}
            </p>
          </div>
          <div className="flex gap-2 mb-3">
            <p className="rounded-[100%] bg-[#E2ECFF]">
              <span className="px-1 text-primary">✓</span>
            </p>
            <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
              {newBotFixCharge}
            </p>
          </div>
          <div className="flex gap-2 mb-3">
            <p className="rounded-[100%] bg-[#E2ECFF]">
              <span className="px-1 text-primary">✓</span>
            </p>
            <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
              {callHistoryValidity}
            </p>
          </div>
          <div className="flex gap-2 mb-3 lg:mb-28">
            <p className="rounded-[100%] bg-[#E2ECFF]">
              <span className="px-1 text-primary">✓</span>
            </p>
            <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
              {messageCenter}
            </p>
          </div>
        </div>
        <button
          className={`${btnStyle}  border text-[16px] px-6 my-1 rounded-lg w-full h-11 font-semibold mb-[59px]`}
          onClick={props.openCheckout}
        >
          {planButton}
        </button>
      </div>
    </>
  );
};

const SubscriptionPlan = () => {
  const navigate = useNavigate();
  const pakaData = useSelector((state) => state.content.pakaData)?.settingPlan;
  const userData = useSelector((state) => state.app.userData);
  const passthrough = { userId: userData.id };
  const passthroughJson = JSON.stringify(passthrough);
  const tabInfo = [
    { title: "Basic" },
    { title: "Advanced" },
    { title: "Expert" },
  ];
  const [selectedTab, setSelectedTab] = useState(tabInfo[0]);
  const [paddleLoaded, setPaddleLoaded] = useState(false);
  const dispatch = useDispatch();
  const openBasicCheckout = () => {
    if (window.Paddle && window.Paddle.Checkout) {
      window.Paddle.Checkout.open({
        product: basicPlanId,
        passthrough: passthroughJson,
        successCallback: function () {
          // Navigate to "/create-bot" on successful payment
          dispatch(setUserData({ subscriptionStatus: "active" }));
          navigate("/create-bot");
        },
      });
    } else {
      console.error("Paddle SDK not loaded.");
    }
  };
  const openAdvancedCheckout = () => {
    if (window.Paddle && window.Paddle.Checkout) {
      window.Paddle.Checkout.open({
        product: advancedPlanId,
        passthrough: passthroughJson,
        successCallback: function () {
          // Navigate to "/create-bot" on successful payment
          dispatch(setUserData({ subscriptionStatus: "active" }));
          navigate("/create-bot");
        },
      });
    } else {
      console.error("Paddle SDK not loaded.");
    }
  };

  const openExpertCheckout = () => {
    if (window.Paddle && window.Paddle.Checkout) {
      window.Paddle.Checkout.open({
        product: expertPlanId,
        passthrough: passthroughJson,
        successCallback: function () {
          // Navigate to "/create-bot" on successful payment
          dispatch(setUserData({ subscriptionStatus: "active" }));
          navigate("/create-bot");
        },
      });
    } else {
      console.error("Paddle SDK not loaded.");
    }
  };

  useEffect(() => {
    // Listen for Paddle SDK loading completion
    window.addEventListener("paddleSdkLoaded", () => {
      setPaddleLoaded(true);
    });

    return () => {
      window.removeEventListener("paddleSdkLoaded", () => {});
    };
  }, []);

  return (
    <>
      <PaddleLoader />
      <h1 className="text-[24px] font-bold text-primary mx-5">Subscription</h1>
      <p className="text-[11px] mb-[14px] mx-5">
        Choose the plan that suits you best
      </p>
      {/* for laptop or large screen */}
      <div className="hidden md:block md:mx-5">
        <div className="flex gap-1 lg:gap-2 xl:gap-8 my-4">
          <Plan
            planType={"Basic"}
            planInfo={pakaData?.basicPlan.subHeading}
            planPrice={pakaData?.basicPlan.price}
            planMin={pakaData?.basicPlan.callDuration}
            planRate={pakaData?.basicPlan.overDurationCharges}
            noOfBots={pakaData?.basicPlan.noOfBots}
            newBotFixCharge={pakaData?.basicPlan.newBotFixCharge}
            callHistoryValidity={pakaData?.basicPlan.callHistoryValidity}
            messageCenter={pakaData?.basicPlan.messageCenter}
            planButton={"Select Plan"}
            //btnStyle={`bg-[#E7E7E7] text-[#7F7F7F]`}
            recStyle={`invisible`}
            btnStyle={`bg-primary text-white transition-all duration-300 ease-in-out 
            hover:bg-secoundry hover:text-white hover:border-secoundry`}
            boxStyle={`border md:px-2 lg:px-5 xl:px-7 w-1/3`}
            openCheckout={openBasicCheckout}
            disabled={!paddleLoaded}
          />
          <Plan
            planType={"Advanced"}
            planInfo={pakaData?.advancedPlan.subHeading}
            planPrice={pakaData?.advancedPlan.price}
            planMin={pakaData?.advancedPlan.callDuration}
            planRate={pakaData?.advancedPlan.overDurationCharges}
            noOfBots={pakaData?.advancedPlan.noOfBots}
            newBotFixCharge={pakaData?.advancedPlan.newBotFixCharge}
            callHistoryValidity={pakaData?.advancedPlan.callHistoryValidity}
            messageCenter={pakaData?.advancedPlan.messageCenter}
            planButton={"Select Plan"}
            boxStyle={`bg-primary text-white border md:px-2 lg:px-5 xl:px-7 w-1/3`}
            btnStyle={`bg-white text-primary transition-all duration-300 ease-in-out 
            hover:bg-secoundry hover:text-white hover:border-secoundry`}
            openCheckout={openAdvancedCheckout}
            disabled={!paddleLoaded}
          />
          <Plan
            planType={"Expert"}
            planInfo={pakaData?.expertPlan.subHeading}
            planPrice={pakaData?.expertPlan.price}
            planMin={pakaData?.expertPlan.callDuration}
            planRate={pakaData?.expertPlan.overDurationCharges}
            noOfBots={pakaData?.expertPlan.noOfBots}
            newBotFixCharge={pakaData?.expertPlan.newBotFixCharge}
            callHistoryValidity={pakaData?.expertPlan.callHistoryValidity}
            messageCenter={pakaData?.expertPlan.messageCenter}
            planButton={"Select Plan"}
            recStyle={`invisible`}
            btnStyle={`bg-primary text-white transition-all duration-300 ease-in-out 
            hover:bg-secoundry hover:text-white hover:border-secoundry`}
            boxStyle={`border md:px-2 lg:px-5 xl:px-7 w-1/3 `}
            openCheckout={openExpertCheckout}
            disabled={!paddleLoaded}
          />
        </div>
      </div>

      {/* for mobile and tabs */}

      <div className="md:hidden mx-1">
        <div className="shadow-card p-2 rounded-xl mb-10">
          <div className="flex justify-between pt-2">
            {tabInfo?.map((tab, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedTab(tab);
                }}
                className={`px-4 py-2 ${
                  selectedTab.title === tab.title &&
                  " text-white bg-secoundry px-2 rounded-lg"
                }`}
              >
                {tab.title}
              </div>
            ))}
          </div>
          {selectedTab.title === "Basic" && (
            <Plan
              planType={"Basic"}
              planInfo={pakaData?.basicPlan.subHeading}
              planPrice={pakaData?.basicPlan.price}
              planMin={pakaData?.basicPlan.callDuration}
              planRate={pakaData?.basicPlan.overDurationCharges}
              noOfBots={pakaData?.basicPlan.noOfBots}
              newBotFixCharge={pakaData?.basicPlan.newBotFixCharge}
              callHistoryValidity={pakaData?.basicPlan.callHistoryValidity}
              messageCenter={pakaData?.basicPlan.messageCenter}
              planButton={"Select Plan"}
              btnStyle={`bg-primary text-white`}
              recStyle={`invisible`}
              boxStyle={`px-4`}
              openCheckout={openBasicCheckout}
              disabled={!paddleLoaded}
            />
          )}
          {selectedTab.title === "Advanced" && (
            <Plan
              planType={"Advanced"}
              planInfo={pakaData?.advancedPlan.subHeading}
              planPrice={pakaData?.advancedPlan.price}
              planMin={pakaData?.advancedPlan.callDuration}
              planRate={pakaData?.advancedPlan.overDurationCharges}
              noOfBots={pakaData?.advancedPlan.noOfBots}
              newBotFixCharge={pakaData?.advancedPlan.newBotFixCharge}
              callHistoryValidity={pakaData?.advancedPlan.callHistoryValidity}
              messageCenter={pakaData?.advancedPlan.messageCenter}
              planButton={"Select Plan"}
              btnStyle={`bg-primary text-white`}
              recStyle={`invisible`}
              boxStyle={`px-4`}
              openCheckout={openAdvancedCheckout}
              disabled={!paddleLoaded}
            />
          )}
          {selectedTab.title === "Expert" && (
            <Plan
              planType={"Expert"}
              planInfo={pakaData?.expertPlan.subHeading}
              planPrice={pakaData?.expertPlan.price}
              planMin={pakaData?.expertPlan.callDuration}
              planRate={pakaData?.expertPlan.overDurationCharges}
              noOfBots={pakaData?.expertPlan.noOfBots}
              newBotFixCharge={pakaData?.expertPlan.newBotFixCharge}
              callHistoryValidity={pakaData?.expertPlan.callHistoryValidity}
              messageCenter={pakaData?.expertPlan.messageCenter}
              planButton={"Select Plan"}
              recStyle={`invisible`}
              btnStyle={`bg-primary text-white`}
              boxStyle={`px-4`}
              openCheckout={openExpertCheckout}
              disabled={!paddleLoaded}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlan;
