import React, { useState } from "react";
import DescriptionInput from "../supportBot/DescriptionInput";
import InputFieldM from "../meetings/InputFieldM";
import { supportBotDataSchema } from "../../utils/schemas";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/axiosInstance";
import { setSupportBot } from "../../redux/AppSlice";
import { toast } from "react-toastify";
import { CgClose } from "react-icons/cg";
import DropZone from "../supportBot/DropZone";
import { TbCloudUpload } from "react-icons/tb";

const CreateSupportBot = ({ setShowModel }) => {
  const pakaData = useSelector(
    (state) => state.content.pakaData
  )?.supportBotContent;
  const dispatch = useDispatch();
  const [createSupportBotData, setCreateSupportBotData] = useState();
  const userData = useSelector((state) => state.app.userData);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    // setTouched,
    // setFieldError,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: supportBotDataSchema,
    initialValues: {
      user_id:
        createSupportBotData != null
          ? createSupportBotData.user_id
          : userData?.id || "",
      topic: createSupportBotData != null ? createSupportBotData.topic : "",
      description:
        createSupportBotData != null ? createSupportBotData.description : "",
      knowledge:
        createSupportBotData != null ? createSupportBotData.knowledge : "",
      file: createSupportBotData != null ? createSupportBotData.file : "",
      knowledgeInFile:
        createSupportBotData != null
          ? createSupportBotData.knowledgeInFile
          : {
              fileName: "",
              knowledge: "",
            },
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      createSupportBot(values, resetForm);
    },
  });

  const handleFileClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    // setFile(event.target.files[0]);
    setFieldValue("file", event.target.files[0]);
  };
  const handleDrop = (files) => {
    console.log(files);
    setFieldValue("file", files[0]);
  };

  // API call for create topic
  const createSupportBot = async (values, resetForm) => {
    const formData = new FormData();
    formData.append("file", values.file);
    formData.append("user_id", values.user_id);
    formData.append("topic", values.topic);
    formData.append("description", values.description);
    formData.append("knowledge", values.knowledge);
    // setLoader(true);
    api
      .post(`/create_support_bot`, formData)
      .then(async ({ data }) => {
        resetForm();
        // setFile(null);
        setFieldValue("file", null);
        setShowModel(false);
        dispatch(setSupportBot(data.supportBotList));
        // setLoader(false);
        toast.success("Support bot Created Successfully", {
          theme: "colored",
          position: "top-center",
          autoClose: 1000,
          progress: false,
          hideProgressBar: true,
          style: { backgroundColor: "green" },
        });
      })
      .catch((error) => {
        if (
          error.response.data.success === false &&
          error.response.data.message === "Token Missing"
        ) {
          //   setLoader(false);
          setShowModel(false);
          toast.error("Please Login Before create support bot!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
          //   navigate("/");
        } else {
          //   setLoader(false);
          setShowModel(false);
          toast.error("Something went wrong!!", {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            progress: false,
            hideProgressBar: true,
          });
        }
        console.log(error, "create Support bot error");
      });
  };

  return (
    <div className="rounded-2xl bg-white w-[90%] lg:max-w-[50%] lg:min-w-[620px] mx-3 lg:mx-0 md:w-8/12 mt-24 lg:mt-8 py-6 h-fit">
      <div className=" w-full flex items-center justify-between mx-4">
        <p className="text-lg text-primary font-semibold mb-2">
          Create a new support bot
        </p>
        <CgClose
          onClick={() => {
            setShowModel(false);
          }}
          className="text-2xl cursor-pointer mr-8 mb-2"
        />
      </div>
      <hr />

      <div className=" mx-[2%] mt-3 flex flex-col items-center md:items-start gap-3">
        <div className="w-[95%] lg:w-full">
          <InputFieldM
            onChange={handleChange("topic")}
            onBlur={handleBlur("topic")}
            title="Topic*"
            value={values.topic}
            placeholder="eg. Demo Paka AI"
            info={pakaData?.topic}
            touched={touched.topic}
            errors={errors.topic}
          />
          <div className=" h-3 pt-1  ">
            {touched.topic && errors.topic && (
              <p className=" text-[11px] text-red-500">{errors.topic}</p>
            )}
          </div>
        </div>
      </div>
      <div className="w-[95%] lg:w-full mt-5">
        <DescriptionInput
          title="Description*"
          onChange={handleChange("description")}
          onBlur={handleBlur("description")}
          value={values.description}
          placeholder="An expert support bot for all types of cell phones that are available for purchase at the GS Electronics store. Can help and give information on prices, parameters and can compare all types of phones."
          info={pakaData?.description}
          className={`mx-4`}
        />
        <div className="-mt-2 h-3 ml-3 ">
          {touched.description && errors.description && (
            <p className=" text-[11px] text-red-500">{errors.description}</p>
          )}
        </div>
      </div>
      <div className="w-[95%] lg:w-full mt-8">
        <DescriptionInput
          title="knowledge"
          onChange={handleChange("knowledge")}
          onBlur={handleBlur("knowledge")}
          value={values.knowledge}
          placeholder="Knowledge of the bot"
          info={pakaData?.knowledge}
          className={`mx-4`}
        />
        <div className="-mt-2 h-3 ml-3 ">
          {touched.knowledge && errors.knowledge && (
            <p className=" text-[11px] text-red-500">{errors.knowledge}</p>
          )}
        </div>
      </div>
      <div className="w-[90%] bg-[#F8F8F8] mx-5 rounded-xl lg:w-[95%] mt-5 border-dashed border-2 border-primary">
        <DropZone onDrop={handleDrop}>
          <div
            className="flex justify-center my-2 cursor-pointer"
            onClick={handleFileClick}
          >
            <TbCloudUpload className=" text-secoundry text-4xl" />
          </div>
          <div className="text-center">
            <span className=" font-semibold">Click to Upload </span>
            <span>or Drag and Drop</span>
            <p className="mb-2">( .txt, .docx Max File size: 25 MB)</p>
            {values.file && (
              <div className="flex items-center justify-center gap-5 mb-2">
                <p className="text-sm text-gray-600 font-semibold">
                  {values.file.name}
                </p>
                <CgClose
                  onClick={() => {
                    // setFile(null);
                    setFieldValue("file", null);
                  }}
                  title={"remove selected file"}
                  className=" text-secoundry font-medium text-md cursor-pointer"
                />
              </div>
            )}
          </div>
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={handleFileChange}
          />
        </DropZone>
      </div>

      <div className="w-full flex justify-center items-center mt-3 text-center">
        <button
          type="submit"
          onClick={handleSubmit}
          className={` flex justify-center w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
        >
          Create Bot
        </button>
        <button
          onClick={() => {
            setShowModel(false);
            setCreateSupportBotData(null);
          }}
          className="w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry py-2 px-3 rounded-md mx-3 text-primary border border-black"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CreateSupportBot;
