import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { BsCheck, BsThreeDotsVertical } from "react-icons/bs";
// import ContactListCard from "./ContactListCard";
import {
  MdCheckBox,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { contactBots } from "../../utils/contacts/functions/contactBots";
import ContactCard from "./ContactCard";
import { PiNotepad } from "react-icons/pi";

const ContactsTable = ({
  listOfContacts,
  setShowModel,
  setSelectedOption,
  selectedContacts,
  setSelectedContacts,
  setCreateContactData,
  setSelectedBots,
  listOfBots,
  selectAll,
  setSelectAll,
}) => {
  const [toggleContactId, setToggleContactId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage, setContactsPerPage] = useState(10);
  const indexOfLastContact = currentPage * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;
  const currentContacts = listOfContacts.slice(
    indexOfFirstContact,
    indexOfLastContact
  );
  const totalPages = Math.ceil(listOfContacts.length / contactsPerPage);
  // const [selectAll, setSelectAll] = useState(false);

  const toggleIndividualSelected = (contactId) => {
    const updatedSelectedContacts = [...selectedContacts];
    const index = updatedSelectedContacts.indexOf(contactId);

    if (index === -1) {
      updatedSelectedContacts.push(contactId);
    } else {
      updatedSelectedContacts.splice(index, 1);
    }

    setSelectedContacts(updatedSelectedContacts);
  };

  const toggleSelectAll = () => {
    const allContactIds = currentContacts.map((contact) => contact._id);
    if (selectedContacts.length === allContactIds.length) {
      setSelectedContacts([]);
      setSelectAll(false);
    } else {
      setSelectedContacts(allContactIds);
      setSelectAll(true);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleContactsPerPageChange = (e) => {
    setContactsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  // Handle mobile view
  const [allOptions] = useState(["Edit", "block", "Delete"]);
  const [blockOptions] = useState(["Edit", "unBlock", "Delete"]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const handleOptionClick = (option, item) => {
    setSelectedOption(option);
    setShowModel(true);
    setIsOpen(false);
    setCreateContactData(item);
    setSelectedContacts([item._id]);
    setSelectedBots(contactBots(listOfBots, item?.bots) || []);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <ToastContainer />
      <div className=" hidden md:block relative bg-white">
        <div className=" grid grid-cols-7 xl:gap-x-6  bg-secoundry py-2  pl-2 rounded-md mt-2 mx-2">
          <div
            className="w-1/4 text-[24px] cursor-pointer pl-5"
            onClick={toggleSelectAll}
          >
            {selectAll ? (
              <BsCheck className="text-white bg-primary rounded" />
            ) : (
              <MdCheckBoxOutlineBlank className=" text-white bg-white border-secoundry border-2 rounded " />
            )}
          </div>
          <p className=" text-[14.86px] text-white font-semibold">First Name</p>
          <p className=" text-[14.86px] text-white font-semibold">Last Name</p>
          <p className=" text-[14.86px] text-white font-semibold"> Phone</p>
          <p className=" text-[14.86px] text-white font-semibold">Email</p>
          <p className=" text-[14.86px] text-white ml-5 font-semibold">Notes</p>
          <p className=" text-[14.86px] text-white -ml-5 font-semibold">Bots</p>
        </div>
      </div>
      <div className=" hidden md:block mx-3 static h-[64vh] overflow-auto">
        {currentContacts.length === 0 ? (
          <div className=" flex items-center justify-center py-5">
            <p className=" text-appgray">You Have No Any Contacts</p>
          </div>
        ) : (
          <div className=" my-3">
            {currentContacts.map((item, index) => (
              <ContactCard
                key={item._id}
                item={item}
                index={index}
                setShowModel={setShowModel}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                setCreateContactData={setCreateContactData}
                indexOfFirstContact={indexOfFirstContact}
                toggleIndividualSelected={toggleIndividualSelected}
                setSelectedOption={setSelectedOption}
                setSelectedBots={setSelectedBots}
                listOfBots={listOfBots}
                toggleContactId={toggleContactId}
                setToggleContactId={setToggleContactId}
              />
            ))}
          </div>
        )}
      </div>
      <hr />
      <div className="hidden md:flex justify-between mt-2 mx-2">
        <div></div>
        <div className="flex gap-4 items-center">
          <div className="w-32">
            <p className="-mt-1">Rows per page:</p>
          </div>
          <div className="mb-1">
            <select
              value={contactsPerPage}
              onChange={handleContactsPerPageChange}
              className="px-2 py-1 border border-gray-100 rounded-md bg-white"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <p className="-mt-1">
            {currentPage} of {totalPages}
          </p>
          <div className="flex gap-6 -mt-1">
            <button
              disabled={currentPage === 1}
              onClick={handlePrevPage}
              className={`text-2xl cursor-pointer ${
                currentPage === 1 ? "text-gray-400" : ""
              }`}
            >
              <MdKeyboardArrowLeft />
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
              className={`text-2xl cursor-pointer ${
                currentPage === totalPages ? "text-gray-400" : ""
              }`}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className="md:hidden block w-full bg-white overflow-x-auto">
        <table className="table-auto w-[800px] border-separate rounded-t-lg -mt-2 border-spacing-y-3">
          <thead className="bg-secoundry rounded-lg">
            <tr className="text-[16px] text-white h-10">
              <th onClick={toggleSelectAll} className="pl-2">
                {selectAll ? (
                  <BsCheck className="text-white text-xl bg-primary rounded" />
                ) : (
                  <MdCheckBoxOutlineBlank className="text-xl text-white bg-white border-secoundry border-2 rounded " />
                )}
              </th>
              <th>First Name</th>
              <th>Last Name</th>
              <th className="text-start pl-8">Phone</th>
              <th className="text-start pl-5">Email</th>
              <th>Notes</th>
              <th>Bots</th>
              <th> </th>
            </tr>
          </thead>
          {currentContacts.length === 0 ? (
            <div className=" flex items-center justify-center py-5">
              <p className=" text-appgray">You Have No Any Meeting Topic</p>
            </div>
          ) : (
            <tbody>
              {currentContacts.map((item, index) => (
                <tr
                  key={item._id}
                  className={`rounded-lg text-[16px] text-ttt font-Poppins font-normal h-8  ${
                    currentContacts.includes(item._id)
                      ? "bg-slate-100"
                      : "hover:bg-slate-200"
                  }`}
                >
                  <td
                    onClick={() => toggleIndividualSelected(item._id)}
                    className="pl-2"
                  >
                    {selectedContacts.includes(item._id) ? (
                      <MdCheckBox className=" text-secoundry text-xl mr-1 mt-1" />
                    ) : (
                      <p className="pl-2">{indexOfFirstContact + index + 1}</p>
                    )}
                  </td>
                  <td className="pl-4">{item?.firstName}</td>
                  <td className="pl-2">{item?.lastName}</td>
                  <td className="pl-8">
                    +{item?.phone?.countryCode + " " + item?.phone?.contact}
                  </td>
                  <td className="pl-8">{item?.email || "-"}</td>
                  <td className="px-8 text-center">
                    <PiNotepad title={item?.note} className="cursor-pointer" />
                  </td>
                  <td className="text-center px-5">{item?.bots?.length}</td>
                  {/* <td ref={ref} className="relative pr-2"> */}
                  <td className="relative px-5">
                    <button
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setToggleContactId(item._id);
                      }}
                    >
                      <BsThreeDotsVertical className={""} />
                    </button>
                    {isOpen && toggleContactId === item._id && (
                      <div
                        className="relative inset-0 flex items-center justify-center z-50"
                        onClick={() => setIsOpen(false)}
                      >
                        <div
                          className="absolute bg-white shadow-dropDownBox rounded-lg overflow-hidden right-6"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {item.blockStatus ? (
                            <ul>
                              {blockOptions.map((option, index) => (
                                <li
                                  onClick={() =>
                                    handleOptionClick(option, item)
                                  }
                                  className="cursor-pointer py-1 px-2 transition-all rounded-lg duration-300 ease-in-out hover:bg-primary hover:text-white"
                                  key={index}
                                >
                                  {option}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <ul>
                              {allOptions.map((option, index) => (
                                <li
                                  onClick={() =>
                                    handleOptionClick(option, item)
                                  }
                                  className="cursor-pointer py-1 px-2 transition-all rounded-lg duration-300 ease-in-out hover:bg-primary hover:text-white"
                                  key={index}
                                >
                                  {option}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <hr />
      <div className="md:hidden mt-2 mr-2 bg-gray-50">
        <div className="flex justify-between gap-2 items-center">
          <div className="flex justify-between ">
            <div className="w-32">
              <p className="ml-1 mt-1 text-[14.88px]">Rows per page:</p>
            </div>
            <div className="mb-1 ">
              <select
                value={contactsPerPage}
                onChange={handleContactsPerPageChange}
                className="py-1 border border-gray-100 rounded-md bg-white"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="flex gap-2">
            <p className="-mt-1">
              {currentPage} of {totalPages}
            </p>
            <div className="flex gap-3 -mt-1">
              <button
                disabled={currentPage === 1}
                onClick={handlePrevPage}
                className={`bg-white text-2xl cursor-pointer ${
                  currentPage === 1 ? "text-gray-400" : ""
                }`}
              >
                <MdKeyboardArrowLeft />
              </button>
              <button
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                className={`bg-white text-2xl cursor-pointer ${
                  currentPage === totalPages ? "text-gray-400" : ""
                }`}
              >
                <MdKeyboardArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="  lg:hidden mx-3  ">
        {listOfContacts?.length === 0 ? (
          <div className=" flex items-center justify-center py-5">
            <p className=" text-appgray">You Have No Any Contacts</p>
          </div>
        ) : (
          <div>
            {listOfContacts?.map((item, index) => {
              const handleDelete = () => {
                setSelectedOption("Delete");
                setShowModel(true);
                setSelectedContacts([item._id]);
              };

              const handleEdit = () => {
                setSelectedOption("Edit");
                setShowModel(true);
                setCreateContactData(item);
                setSelectedBots(contactBots(listOfBots, item?.bots) || []);
              };

              const handleBlock = () => {
                setSelectedOption("block");
                setShowModel(true);
                setSelectedContacts([item._id]);
              };

              const handleUnblock = () => {
                setSelectedOption("unBlock");
                setShowModel(true);
                setSelectedContacts([item._id]);
              };

              return (
                <div key={item._id}>
                  <ContactListCard
                    item={item}
                    index={index}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    onBlock={handleBlock}
                    onUnBlock={handleUnblock}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div> */}
    </>
  );
};

export default ContactsTable;
