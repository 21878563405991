import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdCheckBox } from "react-icons/md";
import { PiNotepad } from "react-icons/pi";

const SupportBotCard = ({
  item,
  index,
  setShowModel,
  selectedSupportBots,
  setSelectedSupportBots,
  setCreateSupportBotData,
  toggleIndividualSelected,
  indexOfFirstSupportBot,
  setSelectedOption,
  toggleSupportBotId,
  setToggleSupportBotId,
}) => {
  const [allOptions] = useState(["Edit", "Delete"]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setCreateSupportBotData(item);
    setSelectedSupportBots([item._id]);
    setShowModel(true);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      key={item._id}
      className={`grid grid-cols-6 xl:gap-x-8 mx-5 py-3 hover:bg-primaryLight ${
        selectedSupportBots.includes(item._id)
          ? "bg-secoundryLight"
          : "bg-slate-50"
      } rounded-lg mt-2 py-1`}
    >
      <div
        className="w-full flex text-center cursor-pointer text-[14.86px] text-ttt pl-5"
        onClick={() => toggleIndividualSelected(item._id)}
      >
        {selectedSupportBots.includes(item._id) ? (
          <MdCheckBox className=" text-secoundry text-xl mr-1 mt-1" />
        ) : (
          <p className="ml-1">{indexOfFirstSupportBot + index + 1}</p>
        )}
      </div>
      <p className="text-[14.86px] text-ttt">{item?.topic}</p>
      <p className="col-span-2 text-[14.86px] text-ttt">{item?.description}</p>
      <div
        className={`text-[24px] ml-9 capitalize  h-5 w-5 filter text-primary cursor-pointer transition-all duration-300 ease-in-out hover:h-6 hover:w-6`}
      >
        {item?.knowledge?.length > 0 ? (
          <PiNotepad title={item?.knowledge} className="cursor-pointer" />
        ) : (
          <p className="ml-3 text-[14.86px] text-ttt">-</p>
        )}
      </div>

      <div className=" flex mt-1 justify-between">
        <div className="text-[20px] h-5 w-5 filter text-primary cursor-pointer transition-all duration-300 ease-in-out hover:h-6 hover:w-6">
          <div className="text-[20px] h-5 w-5 filter text-primary cursor-pointer transition-all duration-300 ease-in-out hover:h-6 hover:w-6">
            <FiEdit
              onClick={() => {
                setSelectedOption("Edit");
                setCreateSupportBotData(item);
                setSelectedSupportBots([item._id]);
                setShowModel(true);
              }}
            />
          </div>
        </div>
        <div ref={ref} className="relative pr-2">
          <button
            onClick={() => {
              setIsOpen(!isOpen);
              setToggleSupportBotId(item._id);
            }}
          >
            <BsThreeDotsVertical className={""} />
          </button>
          {isOpen && toggleSupportBotId === item._id && (
            <div
              className="relative inset-0 flex items-center justify-center z-50"
              onClick={() => setIsOpen(false)}
            >
              <div
                className="absolute bg-white shadow-dropDownBox rounded-lg overflow-hidden right-6"
                onClick={(e) => e.stopPropagation()}
              >
                <ul>
                  {allOptions.map((option) => (
                    <li
                      onClick={() => handleOptionClick(option)}
                      className="cursor-pointer py-1 px-2 transition-all rounded-lg duration-300 ease-in-out hover:bg-primary hover:text-white"
                      key={option}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SupportBotCard;
