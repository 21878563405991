import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthRoutes from "./routes/AuthRoutes";
import Layouts from "./layouts/Layouts";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setPakaData } from "./redux/ContentSlice";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const paths = [
    "/",
    "/reset-password",
    "/register/otp",
    "/register",
    "/check",
  ];
  const newUserPaths = ["/", "/reset-password", "/register", "/subscription"];
  const registeredUserPaths = [
    "/dashboard",
    "/bots",
    "/bots/duplicate-bot",
    "/bots/create-bot",
    "/bots/edit-bot",
    "/call-history",
    "/message-center",
    "/contacts",
    "/meetings",
    "/support-bot",
    "/settings",
    "/subscription",
  ];

  const navigate = useNavigate();

  const getContent = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/get_content`)
      .then((result) => {
        dispatch(setPakaData(result.data.contentData));
      });
  };

  useEffect(() => {
    getContent();
    if (
      sessionStorage.getItem("devOtp") !== null &&
      sessionStorage.getItem("token") !== null &&
      registeredUserPaths.includes(location.pathname)
    ) {
      navigate(location.pathname);
    } else if (
      sessionStorage.getItem("devOtp") !== null &&
      sessionStorage.getItem("token") === null &&
      newUserPaths.includes(location.pathname)
    ) {
      navigate(location.pathname);
    } else if (sessionStorage.getItem("devOtp") !== null) {
      navigate("/");
    } else {
      navigate("/check");
    }
  }, []);

  // Function to get a cookie value by name
  // const getCookie = (name) => {
  //   const cookies = document.cookie
  //     .split("; ")
  //     .find((row) => row.startsWith(`${name}=`));

  //   return cookies ? cookies.split("=")[1] : null;
  // };

  // Example: Get the value of the 'username' cookie
  // const username = getCookie("token");

  // useEffect(() => {
  //   // console.log(Cookies.get("token"), "token in app");
  //   // console.log(Cookies.get("devOtp"), "devOtp in app");
  //   // if(Cookies.get("token")){
  //   //   console.log("This prints if there is a cookie user")
  //   //   return;
  //   // }
  //   getContent();
  //   if (
  //     (Cookies.get("devOtp") !== null ||
  //       sessionStorage.getItem("devOtp") !== null) &&
  //     (Cookies.get("token") !== null ||
  //       sessionStorage.getItem("token") !== null) &&
  //     registeredUserPaths.includes(location.pathname)
  //   ) {
  //     navigate(location.pathname);
  //   } else if (
  //     (Cookies.get("devOtp") !== null ||
  //       sessionStorage.getItem("devOtp") !== null) &&
  //     (Cookies.get("token") === null ||
  //       sessionStorage.getItem("token") === null) &&
  //     newUserPaths.includes(location.pathname)
  //   ) {
  //     navigate(location.pathname);
  //   } else if (
  //     Cookies.get("devOtp") !== null ||
  //     sessionStorage.getItem("devOtp") !== null
  //   ) {
  //     navigate("/");
  //   } else {
  //     navigate("/check");
  //     // console.log(Cookies.get("devOtp"), "dev cookies");
  //   }
  // }, []);

  //This is for without dev protection

  // useEffect(() => {
  //   getContent();
  //   if (
  //     sessionStorage.getItem("token") !== null &&
  //     registeredUserPaths.includes(location.pathname)
  //   ) {
  //     navigate(location.pathname);
  //   } else if (
  //     sessionStorage.getItem("token") === null &&
  //     newUserPaths.includes(location.pathname)
  //   ) {
  //     navigate(location.pathname);
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <div className=" font-Poppins ">
      {paths.includes(location.pathname) ? <AuthRoutes /> : <Layouts />}
    </div>
  );
};

export default App;
