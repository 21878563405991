// export const countryCode = [
//   {
//     name: "Angola",
//     code: 244,
//     flag: require("../images/flag.png"),
//   },
//   {
//     name: "rerrey",
//     code: 249,
//     flag: require("../images/flag.png"),
//   },
//   {
//     name: "wdsadsaf",
//     code: 249,
//     flag: require("../images/flag.png"),
//   },
//   {
//     name: "wtertgfd",
//     code: 144,
//     flag: require("../images/flag.png"),
//   },
//   {
//     name: "oioukhjngj",
//     code: 204,
//     flag: require("../images/flag.png"),
//   },
//   {
//     name: "Angola",
//     code: 24,
//     flag: require("../images/flag.png"),
//   },
//   {
//     name: "Das fsfs",
//     code: 937,
//     flag: require("../images/flag.png"),
//   },
// ];

export const countryCode = [
  {
    ISO: "US",
    Country: "United States",
    Country_Code: 1,
    ISO_CODES: "US / USA",
  },
  {
    ISO: "CA",
    Country: "Canada",
    Country_Code: 1,
    ISO_CODES: "CA / CAN",
  },
  {
    ISO: "IL",
    Country: "Israel",
    Country_Code: 972,
    ISO_CODES: "IL / ISR",
  },
];

export const fullCountryCode = [
  {
    ISO: "US",
    Country: "United States",
    Country_Code: 1,
    ISO_CODES: "US / USA",
  },
  {
    ISO: "CA",
    Country: "Canada",
    Country_Code: 1,
    ISO_CODES: "CA / CAN",
  },
  {
    ISO: "IL",
    Country: "Israel",
    Country_Code: 972,
    ISO_CODES: "IL / ISR",
  },
  {
    ISO: "IN",
    Country: "India",
    Country_Code: 91,
    ISO_CODES: "IN / IND",
  },
  {
    ISO: "GB",
    Country: "United Kingdom",
    Country_Code: 44,
    ISO_CODES: "GB / GBR",
  },
  {
    ISO: "BE",
    Country: "Belgium",
    Country_Code: 32,
    ISO_CODES: "BE / BEL",
  },
  {
    ISO: "CH",
    Country: "Switzerland",
    Country_Code: 41,
    ISO_CODES: "CH / CHE",
  },
  {
    ISO: "DE",
    Country: "Germany",
    Country_Code: 49,
    ISO_CODES: "DE / DEU",
  },
  {
    ISO: "FR",
    Country: "France",
    Country_Code: 33,
    ISO_CODES: "FR / FRA",
  },
  {
    ISO: "FI",
    Country: "Finland",
    Country_Code: 358,
    ISO_CODES: "FI / FIN",
  },
  {
    ISO: "HR",
    Country: "Croatia",
    Country_Code: 385,
    ISO_CODES: "HR / HRV",
  },
  {
    ISO: "SI",
    Country: "Slovenia",
    Country_Code: 386,
    ISO_CODES: "SI / SVN",
  },
  {
    ISO: "JP",
    Country: "Japan",
    Country_Code: 81,
    ISO_CODES: "JP / JPN",
  },
  {
    ISO: "BR",
    Country: "Brazil",
    Country_Code: 55,
    ISO_CODES: "BR / BRA",
  },
  {
    ISO: "HU",
    Country: "Hungary",
    Country_Code: 36,
    ISO_CODES: "HU / HUN",
  },
  {
    ISO: "PL",
    Country: "Poland",
    Country_Code: 48,
    ISO_CODES: "PL / POL",
  },
  {
    ISO: "ZA",
    Country: "South Africa",
    Country_Code: 27,
    ISO_CODES: "ZA / ZAF",
  },
  {
    ISO: "NZ",
    Country: "New Zealand",
    Country_Code: 64,
    ISO_CODES: "NZ / NZL",
  },
  {
    ISO: "AU",
    Country: "Australia",
    Country_Code: 61,
    ISO_CODES: "AU / AUS",
  },
  {
    ISO: "BG",
    Country: "Bulgaria",
    Country_Code: 359,
    ISO_CODES: "BG / BGR",
  },
  {
    ISO: "RO",
    Country: "Romania",
    Country_Code: 40,
    ISO_CODES: "RO / ROU",
  },

  {
    ISO: "AT",
    Country: "Austria",
    Country_Code: 43,
    ISO_CODES: "AT / AUT",
  },
  {
    ISO: "EE",
    Country: "Estonia",
    Country_Code: 372,
    ISO_CODES: "EE / EST",
  },
  {
    ISO: "GR",
    Country: "Greece",
    Country_Code: 30,
    ISO_CODES: "GR / GRC",
  },
  {
    ISO: "PT",
    Country: "Portugal",
    Country_Code: 351,
    ISO_CODES: "PT / PRT",
  },
  {
    ISO: "LS",
    Country: "Lesotho",
    Country_Code: 266,
    ISO_CODES: "LS / LSO",
  },
];
