import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BotsIcon from "../assets/images/Icons/BotsIcon";
// import axios from "axios";
import CustomModal from "../components/CustomModal";
import { Puff, ThreeCircles } from "react-loader-spinner";
import BotTable from "../components/BotTable";
import Toggle from "../components/Toggle";
import { useDispatch, useSelector } from "react-redux";
import { storeBotsList } from "../redux/AppSlice";
import EmailInput from "../components/EmailInput";
import { ToastContainer, toast } from "react-toastify";
import WhatsAppIcon from "../assets/images/Icons/WhatsAppIcon";
import InputField from "../components/InputField";
import DescriptionInput from "../components/DescriptionInput";
import { useFormik } from "formik";
import { whatsAppRequestSchema } from "../utils/schemas";
import api from "../services/axiosInstance";

const Bots = () => {
  const pakaData = useSelector((state) => state.content.pakaData);
  const listOfBots = useSelector((state) => state.app.botsList);
  const userData = useSelector((state) => state.app.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Delete");
  const [selectedBotID, setSelectedBotID] = useState("");
  const [selectedPhoneNumberSID, setSelectedPhoneNumberSID] = useState("");
  const [botPhoneNumber, setBotPhoneNumber] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [getMessage, setGetMessage] = useState(true);
  const [transferCall, setTransferCall] = useState(false);
  const [botSettingsLoader, setBotSettingsLoader] = useState(false);
  const [emails, setEmails] = useState([]);
  const [whatsAppStatus, setWhatsAppStatus] = useState("");
  const [whatsAppData, setWhatsAppData] = useState();
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    // setTouched,
    // setFieldError,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: whatsAppRequestSchema,
    initialValues: {
      user_id: whatsAppData != null ? whatsAppData.user_id : userData?.id || "",
      description: whatsAppData != null ? whatsAppData.description : "",
      businessAddress: whatsAppData != null ? whatsAppData.businessAddress : "",
      email: whatsAppData != null ? whatsAppData.email : "",
      website: whatsAppData != null ? whatsAppData.website : "",
      file: whatsAppData != null ? whatsAppData.file : "",
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      handleFileSubmit(values, resetForm);
    },
  });

  // const [file, setFile] = useState(null);

  // for the file upload
  const handleFileChange = (event) => {
    // setFile(event.target.files[0]);
    setFieldValue("file", event.target.files[0]);
  };
  const handleFileSubmit = async (values, resetForm) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("file", values.file);
    formData.append("user_id", values.user_id);
    formData.append("description", values.description);
    formData.append("businessAddress", values.businessAddress);
    formData.append("email", values.email);
    formData.append("website", values.website);
    formData.append("bot_id", selectedBotID);
    formData.append("botPhoneNumber", botPhoneNumber);

    try {
      api
        .post(`/bots/request_bot_on_whatsApp`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          resetForm();
          if (res.data.botList) {
            dispatch(storeBotsList(res.data.botList));
          }
          setShowModel(false);
          setLoader(false);
          toast.success("requesting WhatsApp service completed Successfully", {
            theme: "colored",
            position: "top-center",
            autoClose: 3000,
            progress: false,
            hideProgressBar: true,
            style: { backgroundColor: "green" },
          });
        });
    } catch (error) {
      console.error("Error uploading file:", error);
      setShowModel(false);
      setLoader(false);
      // setBotSettingsLoader(false);
      toast.error(
        "There is a problem with requesting WhatsApp service, please try again later!",
        {
          theme: "colored",
          position: "top-center",
          autoClose: 3000,
          progress: false,
          hideProgressBar: true,
        }
      );
    }
  };

  const deleteBot = async () => {
    setDeleteLoader(true);
    api
      .post(`/twilio/release_phone_number`, {
        user_id: userData?.id,
        phoneSid: selectedPhoneNumberSID,
      })
      .then(async ({ data }) => {
        api
          .post(`/bots/update_bot_details`, {
            user_id: userData?.id,
            id: selectedBotID,
            status: "archive",
          })
          .then(async ({ data }) => {
            // console.log(data);
            setDeleteLoader(false);
            setShowModel(false);
          })
          .catch((err) => {
            setDeleteLoader(false);
            setShowModel(false);
            toast.error("Something went wrong!!", {
              theme: "colored",
              position: "top-center",
              autoClose: 3000,
              progress: false,
              hideProgressBar: true,
            });
            console.log(err, "update error");
          });
      })
      .catch((e) => {
        setDeleteLoader(false);
        setShowModel(false);
        toast.error("Something went wrong!!", {
          theme: "colored",
          position: "top-center",
          autoClose: 3000,
          progress: false,
          hideProgressBar: true,
        });
        console.log(e, "phone number  release error");
      });
  };

  const botsType = ["All", "Active", "Archived"];
  const [selectedBotType, setSelectedBotType] = useState("Active");

  const saveBotSettings = async () => {
    // console.log(emails, "emails");
    setBotSettingsLoader(true);
    api
      .post(`/bots/update_bot_details`, {
        user_id: userData?.id,
        id: selectedBotID,
        bot_settings: {
          getMessage: getMessage,
          transferCall: transferCall,
          emailsForSendingMessages: emails,
        },
      })
      .then(async (result) => {
        getBotsList();
        setBotSettingsLoader(false);
        setShowModel(false);
        toast.success("Setting Saved", {
          theme: "colored",
          position: "top-center",
          autoClose: 3000,
          progress: false,
          hideProgressBar: true,
          style: { backgroundColor: "green" },
        });
      })
      .catch((err) => {
        console.log(err, "settings error");
        setBotSettingsLoader(false);
        setShowModel(false);
        toast.error("Server is not responding, please try again letter", {
          theme: "colored",
          position: "top-center",
          autoClose: 3000,
          progress: false,
          hideProgressBar: true,
          // style: { backgroundColor: "green" },
        });
      });
  };

  const getBotsList = async () => {
    api
      .post(`/bots/bot_data`, {
        user_id: userData.id,
      })
      .then(({ data }) => {
        // console.log(data, "list of bots");
        dispatch(storeBotsList(data));
      });
  };

  return (
    <div className=" px-3 relative">
      <CustomModal isOpen={loader}>
        <div className=" flex items-center justify-center">
          <div className=" bg-white p-8 h-fit w-fit rounded-md">
            <Puff
              height="100"
              width="100"
              radius={1}
              color="#FF0066"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
      <CustomModal isOpen={showModel}>
        {selectedOption === "Delete" && (
          <div className=" flex flex-col rounded-md items-center bg-white w-full  mx-3 lg:mx-0 md:w-1/2 h-fit mt-32 lg:mt-12 py-6">
            <img
              className=" w-12 h-12"
              alt="del"
              src={require("../assets/images/trash.png")}
            />
            {deleteLoader ? (
              <div className="  my-5  w-full flex flex-col items-center">
                <ThreeCircles
                  height="60"
                  width="60"
                  color="#000032"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                />
                <p className=" mt-5 text-primary">Moving to Archive.....</p>
              </div>
            ) : (
              <div className=" w-full flex flex-col items-center">
                <p className=" text-appgray text-[18px] mx-3 lg:mx-0 lg:w-1/2 flex text-center">
                  Deleting a bot will move it to archive, are you sure?
                </p>
                <button
                  onClick={() => deleteBot()}
                  className={` flex justify-center w-1/2 lg:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
                >
                  Yes, Delete
                </button>
                <button onClick={() => setShowModel(false)} className=" mt-3">
                  No, Keep it
                </button>
              </div>
            )}
          </div>
        )}
        {selectedOption === "Message Settings" && (
          <div className=" flex flex-col rounded-md items-center bg-white w-full  mx-3 lg:mx-0 md:w-1/2 h-fit mt-32 lg:mt-12 py-6">
            <p className=" font-semibold text-primary ">{selectedOption}</p>
            {botSettingsLoader ? (
              <div className="  my-5  w-full flex flex-col items-center">
                <ThreeCircles
                  height="60"
                  width="60"
                  color="#000032"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                />
                <p className=" mt-5 text-primary">Saving....</p>
              </div>
            ) : (
              <>
                <div className=" flex items-center justify-between w-10/12 my-3">
                  <p className=" text-primary">Taking messages by the bot</p>
                  <Toggle
                    onChange={() => setGetMessage(!getMessage)}
                    active={getMessage}
                  />
                </div>
                <div className=" flex items-center justify-between w-10/12 my-3">
                  <p className=" text-primary">
                    Do not transfer calls to the branch after business hours
                  </p>
                  <Toggle
                    onChange={() => {
                      setTransferCall(!transferCall);
                    }}
                    active={transferCall}
                  />
                </div>
                <div className=" w-10/12 ">
                  <p className=" text-primary my-4">
                    Emails for sending the messages from the bot
                  </p>
                  <EmailInput
                    boxClassName=" h-28"
                    emails={emails}
                    setEmails={setEmails}
                  />
                </div>
                <div className=" w-full flex flex-col items-center">
                  <button
                    onClick={() => {
                      saveBotSettings();
                    }}
                    className={` flex justify-center w-1/2 lg:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
                  >
                    Save
                  </button>
                  <button onClick={() => setShowModel(false)} className=" mt-3">
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        )}
        {selectedOption === "Create New" && (
          <div className=" flex flex-col rounded-md items-center bg-white w-full  mx-3 lg:mx-0 md:w-8/12 h-fit mt-32 lg:mt-12 py-6">
            <img
              className=" w-12 h-12"
              alt="bot"
              src={require("../assets/images/dup.png")}
            />
            <p className=" text-primary font-semibold mt-5 mb-2">
              Create a New Bot
            </p>
            <p className=" text-appgray text-center">
              Creating a new bot causes an additional charge.
            </p>
            <div className=" w-full flex flex-col items-center">
              <button
                onClick={() => navigate("/bots/create-bot")}
                className={` flex justify-center w-8/12 lg:w-1/2 xl:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
              >
                Create a new bot
              </button>
              <button onClick={() => setShowModel(false)} className=" mt-3">
                Cancel
              </button>
            </div>
          </div>
        )}
        {selectedOption === "Duplicate and Create New" && (
          <div className=" flex flex-col rounded-md items-center bg-white w-full  mx-3 lg:mx-0 md:w-8/12 h-fit mt-32 lg:mt-12 py-6">
            <img
              className=" w-12 h-12"
              alt="bot"
              src={require("../assets/images/dup.png")}
            />
            <p className=" text-primary font-semibold mt-5 mb-2">
              Duplicate and Create New
            </p>
            <p className=" text-appgray text-center">
              {/* Creating a new bot causes an additional charge. */}
              {pakaData.bot.duplicateCreate}
            </p>
            <div className=" w-full flex flex-col items-center">
              <button
                onClick={() => navigate("/bots/duplicate-bot")}
                className={` flex justify-center w-8/12 lg:w-1/2 xl:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
              >
                Create a new bot
              </button>
              <button onClick={() => setShowModel(false)} className=" mt-3">
                Cancel
              </button>
            </div>
          </div>
        )}
        {selectedOption === "Request WhatsApp Service" && (
          <div className=" flex flex-col max-w-[54rem] rounded-md items-center bg-white w-full  mx-3 lg:mx-0 md:w-8/12 h-fit mt-32 lg:mt-12 py-6">
            <WhatsAppIcon />
            <p className=" text-primary font-semibold mt-5 mb-2">
              Request WhatsApp Service
            </p>
            <p className=" text-appgray text-center">
              Request WhatsApp Service may take upto 48 hours and may causes an
              additional charge.
            </p>
            {whatsAppStatus === "requested" ? (
              <>
                <div className="mt-4">
                  <p className=" text-primary text-center px-2 md:px-16">
                    Thank you for your patience!{" "}
                    <span className=" font-medium">
                      We've received your request to link the bot with WhatsApp,
                    </span>{" "}
                    and our team is diligently working on it. We'll notify you
                    as soon as your bot becomes available on WhatsApp. 🤖📲
                  </p>
                </div>
                <button
                  onClick={() => setShowModel(false)}
                  className=" mt-3 bg-primary py-2 px-8 rounded-md mx-3 text-white"
                >
                  Close
                </button>
              </>
            ) : (
              <>
                <div className="w-[90%] my-5">
                  <DescriptionInput
                    title="Description"
                    onChange={handleChange("description")}
                    onBlur={handleBlur("description")}
                    value={values.description}
                    placeholder="Here you can tell customers about your company"
                    touched={touched.description}
                    errors={errors.description}
                  />
                  <InputField
                    title="Business address"
                    onChange={handleChange("businessAddress")}
                    onBlur={handleBlur("businessAddress")}
                    value={values.businessAddress}
                    placeholder="eg. New York, USA"
                    className={"mb-4"}
                    touched={touched.businessAddress}
                    errors={errors.businessAddress}
                  />
                  <InputField
                    title="Email"
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values.email}
                    placeholder="eg. info@paka.ai"
                    className={"mb-4"}
                    touched={touched.email}
                    errors={errors.email}
                  />
                  <InputField
                    title="Website"
                    onChange={handleChange("website")}
                    onBlur={handleBlur("website")}
                    value={values.website}
                    placeholder="eg. paka.ai"
                    className={"mb-4"}
                    touched={touched.website}
                    errors={errors.website}
                  />
                  <p className="bg-white text-primary font-thin text-sm my-2">
                    Select WhatsApp Profile
                  </p>
                  <input type="file" onChange={handleFileChange} />
                </div>
                <div className=" w-full flex flex-col items-center">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className={` flex justify-center w-8/12 lg:w-1/2 xl:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
                  >
                    Request WhatsApp Service
                  </button>
                  <button
                    onClick={() => {
                      setShowModel(false);
                      setWhatsAppData();
                    }}
                    className=" mt-3"
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </CustomModal>
      <h1 className=" mt-6 lg:mt-0 text-2xl font-bold text-primary ">Bots</h1>
      <div className=" flex justify-between items-center">
        <p className=" text-[11px] text-appgray">
          {/* Take a look at your bots here */}
          {pakaData.bot.subHeading}
        </p>
        <button
          onClick={() => {
            setShowModel(true);
            setSelectedOption("Create New");
          }}
          className=" flex items-center bg-primary cursor-pointer  px-3 py-2 rounded-md mx-3"
        >
          <BotsIcon color={"#fff"} />
          <p className=" text-white mx-2 text-[14px]">Create a new bot</p>
        </button>
      </div>
      <div className="   mt-4 rounded-2xl overflow-hidden shadow-tableShadow bg-white mb-12 lg:mb-0 ">
        <div className=" bg-primary flex items-center px-3 gap-x-4 py-2  ">
          {botsType.map((item, index) => (
            <div
              onClick={() => setSelectedBotType(item)}
              className={`text-white py-0.5 text-[16px] cursor-pointer ${
                selectedBotType === item && " border-b-2 border-b-white"
              }`}
              key={index}
            >
              {item}
            </div>
          ))}
        </div>
        {selectedBotType === "All" && (
          <>
            <BotTable
              listOfBots={listOfBots}
              setSelectedBotID={setSelectedBotID}
              setSelectedOption={(opt) => {
                setSelectedOption(opt);
              }}
              setShowModel={setShowModel}
              setSelectedPhoneNumberSID={setSelectedPhoneNumberSID}
              setSelectedBot={(r) => {
                setGetMessage(r?.bot_settings?.getMessage);
                setTransferCall(r?.bot_settings?.transferCall);
                setEmails(r?.bot_settings?.emailsForSendingMessages);
              }}
              setWhatsAppStatus={setWhatsAppStatus}
              setBotPhoneNumber={setBotPhoneNumber}
              userData={userData}
            />
          </>
        )}

        {selectedBotType === "Active" && (
          <>
            <BotTable
              listOfBots={listOfBots.filter((r) => r.status === "active" && r)}
              setSelectedBotID={setSelectedBotID}
              setSelectedOption={setSelectedOption}
              setShowModel={setShowModel}
              setSelectedPhoneNumberSID={setSelectedPhoneNumberSID}
              setSelectedBot={(r) => {
                setGetMessage(r?.bot_settings?.getMessage);
                setTransferCall(r?.bot_settings?.transferCall);
                setEmails(r?.bot_settings?.emailsForSendingMessages);
              }}
              setWhatsAppStatus={setWhatsAppStatus}
              setBotPhoneNumber={setBotPhoneNumber}
              userData={userData}
            />
          </>
        )}
        {selectedBotType === "Archived" && (
          <>
            <BotTable
              listOfBots={listOfBots.filter((r) => r.status === "archive" && r)}
              setSelectedBotID={setSelectedBotID}
              setSelectedOption={setSelectedOption}
              setShowModel={setShowModel}
              setSelectedPhoneNumberSID={setSelectedPhoneNumberSID}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Bots;
