import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_id: "",
  userData: {
    firstname: "User Name",
    lastname: "",
    subscriptionPlanId: 0,
    subscriptionStatus: "",
  },
  registrationData: {},
  botsList: [],
  selectedBot: {},
  callHistory: [],
  messageCenter: [],
  messageFilteredBot: { _id: 0, botName: "All" },
  settingTab: 0,
  messageSearch: "",
  loading: false,
  topic: [],
  supportBot: [],
  meetings: [],
};
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser_id: (state, actions) => {
      state.user_id = actions.payload;
    },
    setRegistrationData: (state, actions) => {
      state.registrationData = actions.payload;
    },
    setUserData: (state, actions) => {
      state.userData = actions.payload;
    },
    storeBotsList: (state, actions) => {
      state.botsList = actions.payload;
    },
    setSelectBot: (state, actions) => {
      state.selectedBot = actions.payload;
    },
    setCallHistoryData: (state, actions) => {
      state.callHistory = actions.payload;
    },
    setMessageCenterData: (state, actions) => {
      state.messageCenter = actions.payload;
    },
    setMessageFilteredBot: (state, actions) => {
      state.messageFilteredBot = actions.payload;
    },
    setSettingTab: (state, actions) => {
      state.settingTab = actions.payload;
    },
    setMessageSearch: (state, actions) => {
      state.messageSearch = actions.payload;
    },
    setLoading: (state, actions) => {
      state.loading = actions.payload;
    },
    setCallHistoryItem: (state, action) => {
      const { _id, updatedItem } = action.payload;
      const index = state.callHistory.findIndex((item) => item._id === _id);
      if (index !== -1) {
        state.callHistory[index] = updatedItem;
      }
    },
    setTopic: (state, actions) => {
      state.topic = actions.payload;
    },
    setSupportBot: (state, actions) => {
      state.supportBot = actions.payload;
    },
    setMeetings: (state, actions) => {
      state.meetings = actions.payload;
    },
  },
});
export const {
  setUser_id,
  setRegistrationData,
  setUserData,
  storeBotsList,
  setSelectBot,
  setCallHistoryData,
  setMessageCenterData,
  setMessageFilteredBot,
  setSettingTab,
  setMessageSearch,
  setLoading,
  setCallHistoryItem,
  setTopic,
  setSupportBot,
  setMeetings,
} = appSlice.actions;

export default appSlice.reducer;
