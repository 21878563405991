import React, { useState, useEffect } from "react";
import DropDown from "../../components/DropDown";
import Toggle from "../../components/Toggle";
import DaySelector from "../../components/DaySelector";
import InputField from "../../components/InputField";
import DescriptionInput from "../../components/DescriptionInput";
import { MdAdd } from "react-icons/md";
import { FaTrashAlt } from "react-icons/fa";
import { useFormik } from "formik";
import { faqSchema } from "../../utils/schemas";
import TimezoneSelect from "react-timezone-select";

const BusinessFAQs = ({ onNext, goPrevious }) => {
  const [initialData, setInitialData] = useState();
  const { handleSubmit, setFieldValue, values, errors, touched } = useFormik({
    validationSchema: faqSchema,
    initialValues: {
      toggle: initialData != null ? initialData.toggle : false,
      totalWorkingHours:
        initialData != null
          ? initialData.totalWorkingHours
          : [
              {
                day: "Monday",
                active: false,
                workingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Tuesday",
                active: false,
                workingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Wednesday",
                active: false,
                workingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Thursday",
                active: false,
                workingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Friday",
                active: false,
                workingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Saturday",
                active: false,
                workingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Sunday",
                active: false,
                workingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
            ],
      businessFAQ:
        initialData != null
          ? initialData.businessFAQ
          : [
              {
                question: "",
                answer: "",
              },
            ],
      timeZone:
        initialData != null
          ? initialData.timeZone
          : {
              abbrev: "YEKT",
              altName: "Yekaterinburg Standard Time",
              label: "(GMT+5:00) Ekaterinburg",
              offset: 5,
              value: "Asia/Yekaterinburg",
            },
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      goNext(values);
    },
  });

  const handleToggle = (day) => {
    const newArray = values.totalWorkingHours?.map((item, i) => {
      if (item.day === day) {
        return { ...item, active: item.active === true ? false : true };
      } else {
        return item;
      }
    });
    setFieldValue("totalWorkingHours", newArray);
    // setWorkingDaysData(newArray);
  };

  const handleAll = () => {
    setFieldValue("toggle", values.toggle === true ? false : true);
    const newArray = values.totalWorkingHours?.map((item, i) => {
      return {
        ...item,
        active: values.toggle === true ? false : true,
      };
    });
    setFieldValue("totalWorkingHours", newArray);
  };

  const handleAdd = (day) => {
    const newArray = values.totalWorkingHours?.map((item, i) => {
      if (item.day === day) {
        return {
          ...item,
          workingTime: item.workingTime.concat({ from: "", to: "" }),
        };
      } else {
        return item;
      }
    });

    // setWorkingDaysData(newArray);
    setFieldValue("totalWorkingHours", newArray);
  };

  const handleRemove = (day, itemIndex) => {
    const newArray = values.totalWorkingHours?.map((item, i) => {
      if (item.day === day) {
        return {
          ...item,
          workingTime: item.workingTime.slice(0, -1),
        };
      } else {
        return item;
      }
    });

    setFieldValue("totalWorkingHours", newArray);
  };

  const handleFromTime = (day, time, itemIndex) => {
    const newArray = values.totalWorkingHours?.map((item, i) => {
      if (item.day === day) {
        const w = item?.workingTime?.map((it, index) => {
          if (itemIndex === index) {
            return {
              ...it,
              from: time,
            };
          } else {
            return it;
          }
        });
        return {
          ...item,
          workingTime: w,
        };
      } else {
        return item;
      }
    });
    setFieldValue("totalWorkingHours", newArray);
  };

  const handleToTime = (day, time, itemIndex) => {
    const newArray = values.totalWorkingHours?.map((item, i) => {
      if (item.day === day) {
        const w = item?.workingTime?.map((it, index) => {
          if (itemIndex === index) {
            return {
              ...it,
              to: time,
            };
          } else {
            return it;
          }
        });
        return {
          ...item,
          workingTime: w,
        };
      } else {
        return item;
      }
    });
    setFieldValue("totalWorkingHours", newArray);
  };

  const getFaqQuestion = (value, index) => {
    const newArray = values.businessFAQ?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          question: value,
        };
      } else {
        return item;
      }
    });
    setFieldValue("businessFAQ", newArray);
  };
  const getFaqAnswer = (value, index) => {
    const newArray = values.businessFAQ?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          answer: value,
        };
      } else {
        return item;
      }
    });
    //setBFaqs(newArray);
    setFieldValue("businessFAQ", newArray);
  };

  const goNext = () => {
    onNext();
    const formData = {
      toggle: values.toggle,
      totalWorkingHours: values.totalWorkingHours,
      businessFAQ: values.businessFAQ,
      timeZone: values.timeZone,
    };

    localStorage.setItem("faqs", JSON.stringify(formData));
  };
  useEffect(() => {
    setInitialData(JSON.parse(localStorage.getItem("faqs")));
  }, []);

  return (
    <div>
      <div className=" w-full  md:w-11/12 xl:w-1/2 pt-6  ">
        <h3 className=" text-primary font-semibold">Working Hours</h3>
        <div className="flex justify-between items-center mt-5 border font-thin border-border rounded-md h-[48px] w-full px-3 bg-[#F8F8F8]">
          <p className=" text-sm text-primary">Open 24/7</p>
          <Toggle onChange={() => handleAll()} active={values.toggle} />
        </div>
        <h5 className=" text-primary font-normal -mb-3 mt-4">Availability</h5>
        <div className=" h-3 pt-1 ml-3 ">
          {touched.totalWorkingHours && errors.totalWorkingHours && (
            <p className=" text-[11px] text-red-500">
              From time must be less than To time
            </p>
          )}
        </div>
        {values.totalWorkingHours?.map((item, index) => (
          <div key={index}>
            <DaySelector
              title={item.day}
              options={item.workingTime}
              toggleStatus={item.active}
              setFromTime={(r, i) => {
                handleFromTime(item.day, r, i);
              }}
              setToTime={(t, i) => {
                handleToTime(item.day, t, i);
              }}
              handleAdd={() => handleAdd(item.day)}
              onToggleChange={() => handleToggle(item.day)}
              handleRemove={(r) => {
                handleRemove(item.day, r);
              }}
            />
          </div>
        ))}
        <h5 className=" text-primary font-normal mt-4">Time Zone</h5>
        <div className="mb-4 w-full">
          <TimezoneSelect
            value={values.timeZone}
            onChange={(t) => {
              setFieldValue("timeZone", t);
            }}
            className="bg-[#DDDDDD]/40"
          />
        </div>
      </div>
      <div className=" xl:w-9/12">
        <h2 className=" text-primary text-xl ">Add your Business FAQs</h2>
        {values.businessFAQ?.map((item, index) => (
          <div key={index} className="flex">
            <p className="w-[5%] mt-10">
              {index + 1}
              {"."}
            </p>
            <div className="w-[95%] mt-10 relative">
              <div className="">
                <InputField
                  title="Question"
                  onChange={(q) => getFaqQuestion(q.target.value, index)}
                  value={item.question}
                  maxLength={200}
                  placeholder="eg: I can't track my order, where can I find in the application?"
                />
              </div>
              <div className=" mt-5">
                <DescriptionInput
                  onChange={(q) => getFaqAnswer(q.target.value, index)}
                  title="Answer"
                  value={item.answer}
                  maxLength={500}
                  placeholder="eg: To better track your order, you must check it in My Orders under Settings. If still you don't find it, try logging into our website, their you can follow the same step to track your order"
                />
              </div>
              {index > 0 && (
                <FaTrashAlt
                  onClick={() => {
                    let t = values.businessFAQ.filter((_, i) => i !== index);
                    setFieldValue("businessFAQ", t);
                  }}
                  className=" absolute -right-6 cursor-pointer hover:text-red-600 top-5 text-appgray"
                />
              )}
            </div>
          </div>
        ))}
        {values?.businessFAQ?.length < 15 ? (
          <div
            onClick={() => {
              let g = [...values.businessFAQ];

              setFieldValue(
                "businessFAQ",
                g.concat({
                  question: "",
                  answer: "",
                })
              );
            }}
            className="bg-primary ml-12 cursor-pointer h-5 w-5 flex items-center justify-center rounded-full mt-2"
          >
            <MdAdd className="  text-white text-xl " />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className=" flex my-9  mt-10">
        <button
          onClick={goPrevious}
          className=" flex items-center bg-white border-2 border-primary py-1.5 px-10 hover:bg-primary hover:text-white transition-all ease-in-out duration-300 rounded-md mx-3"
        >
          Previous
        </button>
        <button
          type="submit"
          onClick={handleSubmit}
          // onClick={() => goNext()}
          className=" transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BusinessFAQs;
