import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "../../components/OtpInput";
import BlurAnimation from "../../components/BlurAnimation";
import axios from "axios";
import CircularLoader from "../../components/CircularLoader";
import { ToastContainer, toast } from "react-toastify";

const DevOtp = () => {
  const navigate = useNavigate();
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [otpStatus, setOtpStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  //if you want user enter in dev by clicking Next button remove useEffect
  useEffect(() => {
    if (pin4.length === 1) {
      verifyOtp();
    }
  }, [pin4]);

  const verifyOtp = async () => {
    setLoading(true);
    let otp = `${pin1}${pin2}${pin3}${pin4}`;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/dev_pin`,
        {
          devOtp: otp,
        },
        {
          withCredentials: true,
        }
      );

      setOtpStatus(false);
      sessionStorage.removeItem("devOtp");
      sessionStorage.setItem("devOtp", res.data.accessToken);
      toast.success("Welcome to the dev environment", {
        theme: "colored",
        position: "top-center",
        autoClose: 3000,
        progress: false,
        hideProgressBar: true,
        style: { backgroundColor: "green" },
      });
      navigate("/");
    } catch (err) {
      console.log(err);
      toast.warn("Please check your pin, please try again later", {
        theme: "colored",
        position: "top-center",
        autoClose: 4000,
        progress: false,
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleEnterKeyPress = (e) => {
  //   if (e.key === "Enter" && pin4.length === 1) {
  //     verifyOtp();
  //   }
  // };

  return (
    <div className=" relative bg-primary min-h-screen">
      <BlurAnimation />
      <ToastContainer />
      <div className=" flex items-center justify-between px-8 xl:px-12 py-3">
        <div className=" flex items-center">
          <img
            className=" h-8 w-28"
            alt="logo"
            src={require("../../assets/images/Logo1.png")}
          />
        </div>
      </div>
      <div className=" flex flex-col items-center pt-20">
        <h2 className=" text-2xl text-white font-bold">Verify your Identity</h2>
        <div className=" flex flex-col items-center py-4 lg:w-1/2 xl:w-1/3">
          <span className=" text-white">This place for devloper only.</span>
          <p className=" text-white">
            For Paka Services please visit <a href="https://paka.ai">paka.ai</a>
          </p>
          <p className=" text-white">Please Enter the Code Below</p>
        </div>
        <div
          className=" px-4 md:w-1/2 lg:w-1/4 mt-5"
          // onKeyDown={handleEnterKeyPress}
          // tabIndex={0}
        >
          <OtpInput
            setPin1={setPin1}
            setPin2={setPin2}
            setPin3={setPin3}
            setPin4={setPin4}
            SetStatus={setOtpStatus}
          />
        </div>
        {otpStatus && <p className=" text-sm text-red-500 mt-7">Wrong OTP </p>}

        <div className="flex justify-center  w-full mt-4">
          <button
            onClick={() => {
              verifyOtp();
            }}
            className={`${
              loading ? " w-16 h-16 rounded-full " : " w-1/4 rounded-md"
            } bg-secoundry transition-all flex justify-center items-center ease-in-out duration-500 hover:bg-pink-600 font-semibold    py-3 mt-5 text-white `}
          >
            {loading ? <CircularLoader /> : "Next"}
          </button>
        </div>
        <button
          onClick={() => {
            window.location.href = "https://paka.ai";
          }}
          className=" text-appgray my-4"
        >
          Visit paka.ai
        </button>
      </div>
    </div>
  );
};

export default DevOtp;
